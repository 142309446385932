import { Paper, Typography } from '@material-ui/core';
import moment from 'moment';
import { FC } from 'react';
import { ReviewServiceProps } from '../../../pages/Mosa/index.d';
import { useStyles } from '../styles';
function fixTimezone(date: string | Date, invert: boolean = false) {
  const dateObj = new Date(date);
  dateObj.setMinutes(
    invert
      ? dateObj.getMinutes() + dateObj.getTimezoneOffset()
      : dateObj.getMinutes() - dateObj.getTimezoneOffset()
  );
  return dateObj;
}
const Summary: FC<ReviewServiceProps> = ({ data }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Typography variant="caption" className={classes.title}>
        Aeronave
      </Typography>
      <Typography variant="body1" className={classes.value}>
        {data?.aircraft?._model.model} - {data?.aircraft?.prefix}
      </Typography>
      <Typography variant="caption" className={classes.title}>
        Código MOSA
      </Typography>
      <Typography variant="body1" className={classes.value}>
        {data?.code || '---'}
      </Typography>
      <Typography variant="caption" className={classes.title}>
        Base
      </Typography>
      <Typography variant="body1" className={classes.value}>
        {data?.base?.code || '---'}
      </Typography>
      <Typography variant="caption" className={classes.title}>
        Inspection
      </Typography>
      <Typography variant="body1" className={classes.value}>
        {data?.inspection || '---'}
      </Typography>
      <Typography variant="caption" className={classes.title}>
        Hangar - Posição
      </Typography>
      <Typography variant="body1" className={classes.value}>
        {data?.hangar
          ? `${data?.hangar.code} - Posição ${data?.position || '--'}`
          : 'Não Definido'}
      </Typography>
      <Typography variant="caption" className={classes.title}>
        Início do Serviço
      </Typography>
      <Typography variant="body1" className={classes.value}>
        {data?.start_service
          ? moment(fixTimezone(data.start_service, true)).format(
              'DD/MM/Y [às] HH:mm'
            )
          : 'Não Definido'}
      </Typography>
      <Typography variant="caption" className={classes.title}>
        Fim do Serviço
      </Typography>
      <Typography variant="body1" className={classes.value}>
        {data?.end_service
          ? moment(fixTimezone(data.end_service, true)).format(
              'DD/MM/Y [às] HH:mm'
            )
          : 'Não Definido'}
      </Typography>
    </Paper>
  );
};

export default Summary;
