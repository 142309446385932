import { CssBaseline, ThemeProvider } from '@material-ui/core';
import '@fontsource/roboto';
import theme from './styles/theme';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import MomentUtils from '@date-io/moment';
import App from './App';

import store from './store/configureStore';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import './styles/globalStyles.css';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <CssBaseline />
          <App />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorkerRegistration.register();
