import { Button, Grid, Typography } from '@material-ui/core';
import { FormEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { loginUser } from '../../services/api';
import { configureToken } from '../../store/slices/token';
import { configureUser } from '../../store/slices/user';
import LoginFormInputs from '../LoginFormInputs';
import { toast } from 'react-toastify';

const LoginForm = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const permitedRoles = ['AUDITOR MOSA'];

  async function submitUser(event: FormEvent) {
    event.preventDefault();
    const response = await loginUser(username, password);
    if (!response.ok) return toast.error(response.message);
    const user = response.fastTrackingAccess;
    if (!permitedRoles.find((role) => role === user.role)) {
      toast.error('Você não tem permissão para entrar!');
      setUsername('');
      setPassword('');
      return;
    }
    if (response.ok) {
      dispatch(
        configureToken({
          token: response.token,
        })
      );
      dispatch(configureUser({ ...user }));
      toast.success('Login realizado com sucesso!');
      history.push('/');
    } else {
      toast.error(response.message);
    }
  }

  return (
    <form onSubmit={submitUser}>
      <Grid container spacing={2} direction="column">
        <LoginFormInputs
          username={username}
          password={password}
          showPassword={showPassword}
          setUsername={setUsername}
          setPassword={setPassword}
          setShowPassword={setShowPassword}
        />
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            type="submit"
            size="large"
          >
            Entrar
          </Button>
        </Grid>
        <Link
          to="/recuperar-senha"
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '16px',
            textDecoration: 'none',
          }}
        >
          <Typography style={{ color: '#FFF', textDecoration: 'none' }}>
            Esqueceu sua senha?
          </Typography>
        </Link>
      </Grid>
    </form>
  );
};

export default LoginForm;
