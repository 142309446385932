import { Paper, Typography, TextField, MenuItem } from '@material-ui/core';
import { DateTimePicker, TimePicker } from '@material-ui/pickers';
import { FC } from 'react';
import { ServiceProps } from '../../../pages/Mosa/index.d';
import { useStyles } from '../styles';

const positions = ['Hangar n.º', 'Oficina de', 'Pátio', 'Não Aplicável'];

const Identification: FC<ServiceProps> = ({
  data,
  onChangeDate,
  onChange,
  errors,
}) => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Typography variant="h6" className={classes.title}>
        I – Identificação da Auditoria
      </Typography>
      <DateTimePicker
        label="Início da Auditoria"
        onChange={onChangeDate('mosa_start')}
        value={data?.mosa_start || null}
        format="DD/MM/YYYY HH:mm"
        fullWidth
        className={classes.input}
        ampm={false}
        error={errors.includes('mosa_start')}
        helperText={
          errors.includes('mosa_start') &&
          'Por favor, informe a data e hora do início da auditoria'
        }
      />
      <TimePicker
        label="Término da Auditoria"
        onChange={onChangeDate('mosa_end')}
        value={data?.mosa_end || null}
        format="HH:mm"
        fullWidth
        ampm={false}
        className={classes.input}
        error={errors.includes('mosa_end')}
        helperText={
          errors.includes('mosa_end') &&
          'Por favor, informe o horário do término da auditoria'
        }
      />
      <TextField
        className={classes.input}
        value={data?.position_type || null}
        onChange={onChange}
        name="position_type"
        fullWidth
        label="Posição"
        select
      >
        {positions.map((position) => (
          <MenuItem value={position} key={position}>
            {position}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        label="Descrição"
        name="position_description"
        value={data?.position_description || null}
        onChange={onChange}
        fullWidth
        className={classes.input}
      />
    </Paper>
  );
};

export default Identification;
