import {
  getDefaultMiddleware,
  combineReducers,
  configureStore,
} from '@reduxjs/toolkit';

import { userReducer as user } from './slices/user';
import { tokenReducer as token } from './slices/token';
import { reasonsReducer as reasons } from './slices/reasons';
import { aircraftModelsReducer as aircraftModels } from './slices/aircraftModels';
import { notificationsReducer as notifications } from './slices/notifications';
import { basesReducer as bases } from './slices/bases';

const middleware = [...getDefaultMiddleware()];
export const combinedReducers = combineReducers({
  user,
  token,
  reasons,
  aircraftModels,
  notifications,
  bases,
});

const store = configureStore({ reducer: combinedReducers, middleware });

export type StoreTypes = ReturnType<typeof combinedReducers>;
export type AppDispatch = typeof store.dispatch;

export default store;
