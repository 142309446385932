import { Paper, Typography } from '@material-ui/core';
import { FC } from 'react';
import { ReviewServiceProps } from '../../../pages/Mosa/index.d';
import { useStyles } from '../styles';

const References: FC<ReviewServiceProps> = ({ data }) => {
  const classes = useStyles();

  return (
    <>
      <Paper className={classes.root}>
        <Typography variant="body1" className={classes.sectionTitle}>
          IV / V – Dados Técnicos ou Procedimentos Internos de Referência /
          Registros
        </Typography>

        <table className={classes.table}>
          <thead>
            <tr>
              <th>Dados Técnicos</th>
              <th>Registros</th>
            </tr>
          </thead>
          <tbody>
            {!data.references || data.references.length === 0 ? (
              <tr>
                <td>Nenhum produto ou atividade</td>
              </tr>
            ) : (
              data.references.map((reference) => (
                <tr
                  key={`${reference.identification}${reference.register_identification}`}
                >
                  <td>
                    <p>
                      <span className={classes.sectionTitle}>
                        Identificação:{' '}
                      </span>
                      {reference.identification}
                    </p>
                    <p>
                      <span className={classes.sectionTitle}>Tipo: </span>
                      {reference.type}
                    </p>
                    <p>
                      <span className={classes.sectionTitle}>Seção: </span>
                      {reference.section}
                    </p>
                  </td>
                  <td>
                    <p>
                      <span className={classes.sectionTitle}>
                        Identificação:{' '}
                      </span>
                      {reference.register_identification}
                    </p>
                    <p>
                      <span className={classes.sectionTitle}>Tipo: </span>
                      {reference.register_type}
                    </p>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </Paper>
    </>
  );
};
export default References;
