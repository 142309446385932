import {
  Paper,
  Typography,
  TextField,
  IconButton,
  Modal,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormHelperText,
} from '@material-ui/core';
import {
  ChangeEventHandler,
  FC,
  MouseEventHandler,
  useEffect,
  useState,
} from 'react';
import { Add as AddIcon } from '@material-ui/icons';
import { Reference, ServiceProps } from '../../../pages/Mosa/index.d';
import { useStyles } from '../styles';

const References: FC<ServiceProps> = ({ data, setData = () => {}, errors }) => {
  const classes = useStyles();
  const [modalItemIndex, setModalItemIndex] = useState<number | null>(null);

  const handleAddReference = () => {
    setModalItemIndex(data?.references?.length || 0);
    setData({
      references: [
        ...(data?.references || []),
        {
          identification: '',
          type: '',
          section: '',
          register_identification: '',
          register_type: '',
        },
      ],
    });
  };

  const handleReferenceEdit = (index: number) => {
    const handler: MouseEventHandler<HTMLDivElement> = () => {
      setModalItemIndex(index);
    };
    return handler;
  };

  const handleFinishEdit = (referenceData: Reference) => {
    if (modalItemIndex === null) return;
    const referenceListCopy = data.references ? [...data.references] : [];
    referenceListCopy[modalItemIndex] = referenceData;
    setData({ ...data, references: referenceListCopy });
    setModalItemIndex(null);
  };

  const handleDelete = () => {
    if (modalItemIndex === null) return;
    const referenceListCopy = data.references ? [...data.references] : [];
    referenceListCopy.splice(modalItemIndex, 1);
    setData({ ...data, references: referenceListCopy });
    setModalItemIndex(null);
  };

  const handleCloseModal = () => {
    setModalItemIndex(null);
  };

  return (
    <>
      <Paper className={classes.root}>
        <Typography variant="h6" className={classes.title}>
          IV / V – Dados Técnicos ou Procedimentos Internos de Referência /
          Registros
        </Typography>
        <Typography variant="h6" className={classes.title}>
          Referências{' '}
          <IconButton color="primary" onClick={handleAddReference}>
            <AddIcon />
          </IconButton>
        </Typography>
        {!data?.references || data.references.length === 0 ? (
          <Typography variant="body1" className={classes.title}>
            Nenhuma referência inserida
          </Typography>
        ) : (
          data.references.map((reference, index) => (
            <ReferenceCard
              key={index}
              reference={reference}
              onClick={handleReferenceEdit(index)}
            />
          ))
        )}
        {errors.includes('references') && (
          <FormHelperText
            error={errors.includes('references')}
            style={{ textAlign: 'center' }}
          >
            Por favor, informe os produtos/atividades auditados
          </FormHelperText>
        )}
      </Paper>
      <ReferenceModal
        reference={
          modalItemIndex !== null && data.references
            ? data.references[modalItemIndex]
            : undefined
        }
        open={Boolean(modalItemIndex !== null && data.references)}
        onClose={handleCloseModal}
        onFinish={handleFinishEdit}
        onDelete={handleDelete}
      />
    </>
  );
};
const ReferenceCard: FC<{
  reference: Reference;
  onClick: MouseEventHandler<HTMLDivElement>;
}> = ({ reference, onClick }) => {
  const classes = useStyles();
  return (
    <Paper className={classes.card} onClick={onClick}>
      <div className="subSection">
        <Typography variant="body1" className={`${classes.title} colored`}>
          Dados Técnicos
        </Typography>
        <span className={classes.cardItem}>
          <span className="label">Identificação: </span>
          {reference?.identification}
        </span>
        <span className={classes.cardItem}>
          <span className="label">Tipo: </span>
          {reference?.type}
        </span>
        <span className={classes.cardItem}>
          <span className="label">Seção: </span>
          {reference?.section}
        </span>
      </div>
      <div className="subSection">
        <Typography variant="body1" className={`${classes.title} coloredLight`}>
          Registros
        </Typography>
        <span className={`${classes.cardItem} light`}>
          <span className="label">Identificação: </span>
          {reference?.register_identification}
        </span>
        <span className={`${classes.cardItem} light`}>
          <span className="label">Tipo: </span>
          {reference?.register_type}
        </span>
      </div>
    </Paper>
  );
};

type ReferenceModalProps = {
  open: boolean;
  reference?: Reference;
  onFinish: (data: Reference) => void;
  onDelete: () => void;
  onClose: () => void;
};

const ReferenceModal: FC<ReferenceModalProps> = ({
  reference,
  onFinish,
  open,
  onClose,
  onDelete,
}) => {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [data, setData] = useState<Reference>({
    identification: '',
    type: '',
    section: '',
    register_identification: '',
    register_type: '',
  });

  useEffect(() => {
    if (reference) setData(reference);
  }, [reference]);

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleConfirmExclusion = () => {
    onDelete();
    handleCloseDialog();
  };

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { name, value } = e.target;
    setData((currentData) => ({ ...currentData, [name]: value }));
  };

  const handleSave = () => {
    onFinish(data);
  };

  return (
    <>
      <ConfirmationDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        onConfirm={handleConfirmExclusion}
      />
      <Modal open={open} onClose={onClose}>
        <div className={classes.modalWrapper}>
          <Paper className={classes.root}>
            <Typography variant="h6" className={classes.title}>
              Adicionar Referência
            </Typography>
            <Typography variant="body1" className={`${classes.title} colored`}>
              Dados Técnicos ou Procedimentos Internos
            </Typography>
            <TextField
              fullWidth
              value={data?.identification || ''}
              name="identification"
              onChange={handleChange}
              label="Identificação"
            />
            <TextField
              fullWidth
              value={data?.type || ''}
              name="type"
              onChange={handleChange}
              label="Tipo"
            />
            <TextField
              fullWidth
              value={data?.section || ''}
              name="section"
              onChange={handleChange}
              label="Seção"
            />
            <Typography variant="body1" className={`${classes.title} colored`}>
              Dados Técnicos ou Procedimentos Internos
            </Typography>
            <TextField
              fullWidth
              value={data?.register_identification || ''}
              name="register_identification"
              onChange={handleChange}
              label="Identificação"
            />
            <TextField
              fullWidth
              value={data?.register_type || ''}
              name="register_type"
              onChange={handleChange}
              label="Tipo"
            />
            <div className={classes.buttonGroup}>
              <Button color="secondary" onClick={handleOpenDialog}>
                Excluir
              </Button>
              <span>
                <Button color="primary" onClick={handleSave}>
                  Confirmar
                </Button>
                <Button color="default" onClick={onClose}>
                  Cancelar
                </Button>
              </span>
            </div>
          </Paper>
        </div>
      </Modal>
    </>
  );
};

const ConfirmationDialog: FC<{
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}> = ({ open, onClose, onConfirm }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Tem certeza?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Você realmente deseja remover este item da lista?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onConfirm} color="primary" autoFocus>
          Confirmar
        </Button>
        <Button color="secondary" onClick={onClose}>
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default References;
