import {
  Paper,
  Typography,
  TextField,
  IconButton,
  Modal,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Switch,
  FormControlLabel,
} from '@material-ui/core';
import {
  ChangeEventHandler,
  FC,
  MouseEventHandler,
  useEffect,
  useState,
} from 'react';
import { Add as AddIcon } from '@material-ui/icons';
import { Event, ServiceProps } from '../../../pages/Mosa/index.d';
import { useStyles } from '../styles';

const Events: FC<ServiceProps> = ({ data, setData = () => {} }) => {
  const classes = useStyles();
  const [modalItemIndex, setModalItemIndex] = useState<number | null>(null);

  const handleAddEvent = () => {
    setModalItemIndex(data?.events?.length || 0);
    setData({
      events: [
        ...(data?.events || []),
        {
          description: '',
          management: '',
        },
      ],
    });
  };

  const handleEventEdit = (index: number) => {
    const handler: MouseEventHandler<HTMLDivElement> = () => {
      setModalItemIndex(index);
    };
    return handler;
  };

  const handleFinishEdit = (eventData: Event) => {
    if (modalItemIndex === null) return;
    const eventListCopy = data.events ? [...data.events] : [];
    eventListCopy[modalItemIndex] = eventData;
    setData({ ...data, events: eventListCopy });
    setModalItemIndex(null);
  };

  const handleDelete = () => {
    if (modalItemIndex === null) return;
    const eventListCopy = data.events ? [...data.events] : [];
    eventListCopy.splice(modalItemIndex, 1);
    setData({ ...data, events: eventListCopy });
    setModalItemIndex(null);
  };

  const handleCloseModal = () => {
    setModalItemIndex(null);
  };

  const handleSwitch = (e: any, checked: boolean) => {
    setData({ ...data, has_events: checked });
  };

  return (
    <>
      <Paper className={classes.root}>
        <Typography variant="h6" className={classes.title}>
          VIII – Eventos Observados
        </Typography>
        <FormControlLabel
          value={data?.has_events}
          control={<Switch color="primary" />}
          label="Há ocorrência de eventos?"
          labelPlacement="start"
          onChange={handleSwitch}
          style={{ alignSelf: 'flex-end', display: 'flex' }}
        />
        {data?.has_events && (
          <>
            <Typography variant="h6" className={classes.title}>
              Eventos{' '}
              <IconButton color="primary" onClick={handleAddEvent}>
                <AddIcon />
              </IconButton>
            </Typography>
            {!data?.events || data.events.length === 0 ? (
              <Typography variant="body1" className={classes.title}>
                Nenhum evento inserido
              </Typography>
            ) : (
              data.events.map((event, index) => (
                <EventCard
                  key={index}
                  event={event}
                  onClick={handleEventEdit(index)}
                />
              ))
            )}
          </>
        )}
      </Paper>
      <EventModal
        event={
          modalItemIndex !== null && data.events
            ? data.events[modalItemIndex]
            : undefined
        }
        open={Boolean(modalItemIndex !== null && data.events)}
        onClose={handleCloseModal}
        onFinish={handleFinishEdit}
        onDelete={handleDelete}
      />
    </>
  );
};
const EventCard: FC<{
  event: Event;
  onClick: MouseEventHandler<HTMLDivElement>;
}> = ({ event, onClick }) => {
  const classes = useStyles();
  return (
    <Paper className={classes.card} onClick={onClick}>
      <div className="subSection">
        <span className={classes.cardItem}>
          <span className="label">Ameaça: </span>
          {event?.description}
        </span>
        <span className={classes.cardItem}>
          <span className="label">Gerenciamento: </span>
          {event?.management}
        </span>
      </div>
    </Paper>
  );
};

type EventModalProps = {
  open: boolean;
  event?: Event;
  onFinish: (data: Event) => void;
  onDelete: () => void;
  onClose: () => void;
};

const EventModal: FC<EventModalProps> = ({
  event,
  onFinish,
  open,
  onClose,
  onDelete,
}) => {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [data, setData] = useState<Event>({
    description: '',
    management: '',
  });

  useEffect(() => {
    if (event) setData(event);
  }, [event]);

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleConfirmExclusion = () => {
    onDelete();
    handleCloseDialog();
  };

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { name, value } = e.target;
    setData((currentData) => ({ ...currentData, [name]: value }));
  };

  const handleSave = () => {
    onFinish(data);
  };

  return (
    <>
      <ConfirmationDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        onConfirm={handleConfirmExclusion}
      />
      <Modal open={open} onClose={onClose}>
        <div className={classes.modalWrapper}>
          <Paper className={classes.root}>
            <Typography variant="h6" className={classes.title}>
              Adicionar Evento
            </Typography>
            <TextField
              fullWidth
              value={data?.description || ''}
              name="description"
              onChange={handleChange}
              label="Descrição da Ameaça"
            />
            <TextField
              fullWidth
              value={data?.management || ''}
              name="management"
              onChange={handleChange}
              label="Gerenciamento da Ameaça"
              multiline
            />
            <div className={classes.buttonGroup}>
              <Button color="secondary" onClick={handleOpenDialog}>
                Excluir
              </Button>
              <span>
                <Button color="primary" onClick={handleSave}>
                  Confirmar
                </Button>
                <Button color="default" onClick={onClose}>
                  Cancelar
                </Button>
              </span>
            </div>
          </Paper>
        </div>
      </Modal>
    </>
  );
};

const ConfirmationDialog: FC<{
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}> = ({ open, onClose, onConfirm }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Tem certeza?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Você realmente deseja remover este item da lista?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onConfirm} color="primary" autoFocus>
          Confirmar
        </Button>
        <Button color="secondary" onClick={onClose}>
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Events;
