import { Route } from 'react-router-dom';
import ProtectedRoute from '../ProtectedRoute';
import CreateService from '../../pages/CreateService';
import Home from '../../pages/Home';
import Login from '../../pages/Login';
import Mosa from '../../pages/Mosa';
import RecoverPassword from '../../pages/RecoverPassword';
import MosaServices from '../../pages/MosaServices';
import NonMosaServices from '../../pages/NonMosaServices';
import Profile from '../../pages/Profile';

const Routes = () => {
  return (
    <>
      <Route path="/login" exact component={Login} />
      <Route path="/recuperar-senha" exact component={RecoverPassword} />
      <ProtectedRoute path="/" exact component={<Home />} />
      <ProtectedRoute path="/profile" exact component={<Profile />} />
      <ProtectedRoute path="/services/:id" component={<MosaServices />} />
      <ProtectedRoute path="/services" exact component={<MosaServices />} />
      <ProtectedRoute path="/mosa/:id" component={<Mosa />} />
      <ProtectedRoute
        path="/non-mosa-services/:id"
        component={<NonMosaServices />}
      />
      <ProtectedRoute
        path="/create-service/:id"
        component={<CreateService />}
      />
    </>
  );
};

export default Routes;
