import {
  Card,
  Typography,
  makeStyles,
  Fade,
  CardContent,
  CardHeader,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useState, useEffect, useCallback } from 'react';

import shareImg from '../../assets/images/share-ios-icon.png';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    bottom: theme.spacing(2),
    width: 'calc(100% - 32px)',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  cardHeader: {
    paddingBottom: 0,
  },
  cardContent: {
    paddingTop: 0,
  },
  iconButtom: {
    paddingBottom: 0,
  },
  shareImg: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

const AddtoHomescreenBanner = () => {
  const classes = useStyles();
  const [showPopup, setShowPopup] = useState(false);

  const didUserDenied = useCallback(() => {
    return sessionStorage.getItem('addToHomescreenStatus') === 'closed';
  }, []);

  const isIos = useCallback(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  }, []);

  const isInStandaloneMode = useCallback(() => {
    return window.matchMedia('(display-mode: standalone)').matches;
  }, []);

  useEffect(() => {
    if (isIos() && !isInStandaloneMode() && !didUserDenied()) {
      setShowPopup(true);
    }
  }, [isIos, isInStandaloneMode, didUserDenied]);

  function handleClose() {
    if (!showPopup) return;
    sessionStorage.setItem('addToHomescreenStatus', 'closed');
    setShowPopup(false);
  }

  return (
    <Fade in={showPopup}>
      <Card className={classes.root}>
        <CardHeader
          action={
            <IconButton
              aria-label='fechar'
              onClick={handleClose}
              className={classes.iconButtom}
            >
              <CloseIcon />
            </IconButton>
          }
          className={classes.cardHeader}
        />
        <CardContent className={classes.cardContent}>
          <Typography variant='h6' component='h2' gutterBottom>
            Instalar aplicativo
          </Typography>
          <Typography component='span'>Clique em</Typography>
          <img
            src={shareImg}
            alt='Ícone de compartilhar do iOS'
            className={classes.shareImg}
          />
          <Typography component='span'>
            e depois em "Adicionar à tela inicial" para instalar este aplicativo
            em seu celular.
          </Typography>
        </CardContent>
      </Card>
    </Fade>
  );
};

export default AddtoHomescreenBanner;
