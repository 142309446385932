import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter, Switch } from 'react-router-dom';
import Routes from './components/Routes';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddtoHomescreenBanner from './components/AddToHomescreenBanner/';
import useLogin from './hooks/useLogin';
import { fetchReasons } from './store/slices/reasons';
import { fetchAircraftModels } from './store/slices/aircraftModels';
import { fetchNotifications } from './store/slices/notifications';
import { fetchBases } from './store/slices/bases';

const App = () => {
  const { token, user, loadFromLocalStorage, saveToLocalStorage } = useLogin();
  const dispatch = useDispatch();

  useEffect(() => {
    if (token && user.id) {
      dispatch(fetchReasons(token));
      dispatch(fetchAircraftModels(token));
    }
  }, [token, dispatch, user.id]);

  useEffect(() => {
    if (token && user.id) {
      dispatch(fetchBases(token));
      dispatch(fetchNotifications(token, user.id));
    }
  }, [token, dispatch, user.id, user.auditing]);

  // Save and load token from local storage
  useEffect(() => {
    saveToLocalStorage();
  }, [saveToLocalStorage]);
  useEffect(() => {
    loadFromLocalStorage();
  }, [loadFromLocalStorage]);

  return (
    <>
      <BrowserRouter basename="/app">
        <Switch>
          <Routes />
        </Switch>
        <ToastContainer />
        <AddtoHomescreenBanner />
      </BrowserRouter>
    </>
  );
};

export default App;
