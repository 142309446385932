import { Paper, Typography } from '@material-ui/core';
import { FC } from 'react';
import { ReviewServiceProps } from '../../../pages/Mosa/index.d';
import { useStyles } from '../styles';

const PresenceList: FC<ReviewServiceProps> = ({ data }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Typography variant="body1" className={classes.sectionTitle}>
        IX - Lista de Presença
      </Typography>
      <table className={classes.table}>
        <thead>
          <tr>
            <th>Auditados</th>
          </tr>
        </thead>
        <tbody>
          {!data.presence_list || data.presence_list.length === 0 ? (
            <tr>
              <td>Nenhum auditado</td>
            </tr>
          ) : (
            data.presence_list.map((mechanic) => (
              <tr key={mechanic.mechanic._id}>
                <td>
                  <p>
                    {mechanic.mechanic.registration} - {mechanic.mechanic.name}
                  </p>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </Paper>
  );
};

export default PresenceList;
