import { useSelector } from 'react-redux';
import { StoreTypes } from '../store/configureStore';

const useBases = () => {
  const {
    data: basesData,
    error: basesError,
    loading: basesLoading,
  } = useSelector((store: StoreTypes) => store.bases);
  return { basesData, basesError, basesLoading };
};

export default useBases;
