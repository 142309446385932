import { Paper, Typography } from '@material-ui/core';
import {
  Flight as AircraftIcon,
  FindInPage as MOSAIcon,
  Build as InspectionIcon,
  InsertInvitation as DateIcon,
} from '@material-ui/icons';
import moment from 'moment';
import { FC } from 'react';
import { ServiceProps } from '../../../pages/Mosa/index.d';
import { useStyles } from '../styles';
function fixTimezone(date: string | Date, invert: boolean = false) {
  const dateObj = new Date(date);
  dateObj.setMinutes(
    invert
      ? dateObj.getMinutes() + dateObj.getTimezoneOffset()
      : dateObj.getMinutes() - dateObj.getTimezoneOffset()
  );
  return dateObj;
}
const AirportIcon = () => {
  return (
    <svg style={{ width: '1.5rem', height: '1.5rem' }} viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M14.97,5.92C14.83,5.41 14.3,5.1 13.79,5.24L10.39,6.15L5.95,2.03L4.72,2.36L7.38,6.95L4.19,7.8L2.93,6.82L2,7.07L3.66,9.95L14.28,7.11C14.8,6.96 15.1,6.43 14.97,5.92M21,10L20,12H15L14,10L15,9H17V7H18V9H20L21,10M22,20V22H2V20H15V13H20V20H22Z"
      />
    </svg>
  );
};
const PositionIcon = () => {
  return (
    <svg style={{ width: '1.5rem', height: '1.5rem' }} viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M19,20H17V11H7V20H5V9L12,5L19,9V20M8,12H16V14H8V12M8,15H16V17H8V15M16,18V20H8V18H16Z"
      />
    </svg>
  );
};

const Summary: FC<ServiceProps> = ({ data }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Typography variant="caption" className={classes.title}>
        <AircraftIcon /> - Aeronave
      </Typography>
      <Typography variant="body1" className={classes.value}>
        {data?.aircraft?._model.model} - {data?.aircraft?.prefix}
      </Typography>
      <Typography variant="caption" className={classes.title}>
        <MOSAIcon /> - Código MOSA
      </Typography>
      <Typography variant="body1" className={classes.value}>
        {data?.code || '---'}
      </Typography>
      <Typography variant="caption" className={classes.title}>
        <AirportIcon /> - Base
      </Typography>
      <Typography variant="body1" className={classes.value}>
        {data?.base?.code || '---'}
      </Typography>
      <Typography variant="caption" className={classes.title}>
        <InspectionIcon /> - Inspection
      </Typography>
      <Typography variant="body1" className={classes.value}>
        {data?.inspection || '---'}
      </Typography>
      <Typography variant="caption" className={classes.title}>
        <PositionIcon /> - Hangar - Posição
      </Typography>
      <Typography variant="body1" className={classes.value}>
        {data?.hangar
          ? `${data?.hangar.code} - Posição ${data?.position || '--'}`
          : 'Não Definido'}
      </Typography>
      <Typography variant="caption" className={classes.title}>
        <DateIcon /> - Data e Hora de Serviço
      </Typography>
      <Typography variant="body1" className={classes.value}>
        {data?.start_service
          ? moment(fixTimezone(data.start_service, true)).format(
              'DD/MM/Y [às] HH:mm'
            )
          : 'Não Definido'}
      </Typography>
    </Paper>
  );
};

export default Summary;
