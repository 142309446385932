import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  makeStyles,
  Typography,
  IconButton,
} from '@material-ui/core';
import { ServiceCardProps } from '../ServiceCard';
import useFetch from '../../hooks/useFetch';
import { editService } from '../../services/api';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ChangeBaseModal, { ChangeBaseProps } from '../ChangeBaseModal';
import CloseIcon from '@material-ui/icons/Close';
import { useHistory } from 'react-router-dom';
import DenyMosaModal from '../DenyMosaModal';
import useLogin from '../../hooks/useLogin';
import useBases from '../../hooks/useBaseServices';
import { BaseProps } from '../../store/slices/bases';

type ManageMosaModalProps = {
  service: ServiceCardProps;
  open: boolean;
  setOpen: (open: boolean) => void;
  setService: any;
};

export type DenyMosaProps = {
  reason?: string;
  observation?: string;
};

const useStyles = makeStyles((theme) => ({
  listItemWrapper: {
    padding: 0,
  },
  listItemText: {
    '& span': {
      fontSize: theme.typography.body2.fontSize,
      color: theme.palette.primary.dark,
    },
    '& p': {
      fontSize: theme.typography.body2.fontSize,
      color: theme.palette.text.primary,
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  mosaManagmentButtonsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    '& > button': {
      flex: 1,
    },
    '& > button + button': {
      marginLeft: theme.spacing(1),
    },
  },
  changeBaseButtonWrapper: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(2),
  },
}));

const ManageMosaModal = ({
  service,
  open,
  setOpen,
  setService,
}: ManageMosaModalProps) => {
  const classes = useStyles();
  const [changeBaseModalOpened, setChangeBaseModalOpened] = useState(false);
  const [denyMosaModalOpened, setDenyMosaModalOpened] = useState(false);
  const [denyMosaModalValue, setDenyMosaModalValue] = useState<DenyMosaProps>({
    reason: '',
    observation: '',
  });
  const [changeBaseModalValue, setChangeBaseModalValue] =
    useState<ChangeBaseProps>({
      base: service.base?.id,
      hangar: service.hangar?.id,
      position: service.position,
    });
  const [bases, setBases] = useState<BaseProps[]>([]);
  const {
    user: { id },
    token,
  } = useLogin();
  const {
    data: changeBaseData,
    error: changeBaseError,
    apiRequest: changeBaseRequest,
  } = useFetch();
  const {
    data: denyMosaData,
    error: denyMosaError,
    apiRequest: denyMosaRequest,
  } = useFetch();
  const { basesData, basesLoading } = useBases();
  const history = useHistory();

  const handleClose = useCallback(() => {
    setOpen(false);
    setChangeBaseModalOpened(false);
    setDenyMosaModalOpened(false);
  }, [setOpen]);

  useEffect(() => {
    if (basesData) {
      setBases(basesData);
    }
  }, [basesData]);

  // Lidar com erros
  useEffect(() => {
    if (changeBaseData.length !== 0 || denyMosaData.length !== 0) {
      setService(null);
      toast.success('Operação realizada com sucesso');
      handleClose();
    } else if (changeBaseError || denyMosaError) {
      toast.error(
        'Ocorreu um erro durante a operação, tente novamente mais tarde.'
      );
      handleClose();
    }
  }, [
    changeBaseError,
    changeBaseData,
    handleClose,
    setService,
    denyMosaError,
    denyMosaData,
  ]);

  function handleChangeBase() {
    changeBaseRequest(
      editService({
        service: {
          ...service,
          base: { _id: changeBaseModalValue.base },
          hangar: { _id: changeBaseModalValue.hangar },
          position: changeBaseModalValue.position,
        },
        userId: id,
        token,
      })
    );
  }

  function handleDenyMosa() {
    denyMosaRequest(
      editService({
        service: {
          ...service,
          mosa_status: 'MOSA não Realizada',
          mosa_sent: new Date(),
          inspector: {
            inspector: id,
            confirmed: true,
            confirmation_date: new Date(),
          },
          reason: denyMosaModalValue.reason,
          reason_obs: denyMosaModalValue.observation,
        },
        userId: id,
        token,
      })
    );
  }

  function handleAllowMosa() {
    history.push(`/mosa/${service._id}`);
  }

  return (
    <>
      <Dialog
        open={open}
        scroll='paper'
        fullWidth
        maxWidth='sm'
        onClose={handleClose}
      >
        <DialogTitle>
          <Typography variant='h6'>Autorizar MOSA</Typography>
          <IconButton
            aria-label='fechar diálogo'
            onClick={handleClose}
            className={classes.closeButton}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.mosaManagmentButtonsWrapper}>
          <Button
            onClick={() => setDenyMosaModalOpened(true)}
            color='secondary'
            variant='contained'
          >
            Não autorizar mosa
          </Button>
          <Button onClick={handleAllowMosa} color='primary' variant='contained'>
            Autorizar mosa
          </Button>
        </DialogContent>
        <DialogActions className={classes.changeBaseButtonWrapper}>
          <Button
            onClick={() => setChangeBaseModalOpened(true)}
            color='secondary'
            variant='contained'
            style={{ backgroundColor: '#4EAE57' }}
            fullWidth
          >
            Alterar base
          </Button>
        </DialogActions>
      </Dialog>
      <DenyMosaModal
        open={denyMosaModalOpened}
        setDenyMosaModalValue={setDenyMosaModalValue}
        onClose={() => setDenyMosaModalOpened(false)}
        onConfirm={handleDenyMosa}
      />
      {service?._id && (
        <ChangeBaseModal
          open={changeBaseModalOpened}
          setChangeBaseModalValue={setChangeBaseModalValue}
          onClose={() => setChangeBaseModalOpened(false)}
          onConfirm={handleChangeBase}
          baseId={service._id}
          bases={bases}
          basesLoading={basesLoading}
        />
      )}
    </>
  );
};

export default ManageMosaModal;
