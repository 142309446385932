import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type UserProps = {
  active: boolean;
  auditing: boolean;
  imported: boolean;
  password_updated: boolean;
  enable_maintenance_web: boolean;
  _id: string;
  name: string;
  login: string;
  email: string;
  group: string;
  role: string;
  registration: string;
  auditing_areas: [object];
  createdAt: string;
  updatedAt: string;
  __v: number;
  auditing_areas_descr: string;
  id: string;
};

const initialState: UserProps = {
  active: false,
  auditing: false,
  imported: false,
  password_updated: false,
  enable_maintenance_web: false,
  _id: '',
  name: '',
  login: '',
  email: '',
  group: '',
  role: '',
  registration: '',
  auditing_areas: [{}],
  createdAt: '',
  updatedAt: '',
  __v: 1,
  auditing_areas_descr: '',
  id: '',
};

const userSlicer = createSlice({
  name: 'user',
  initialState,
  reducers: {
    configureUser(state, action: PayloadAction<UserProps>) {
      state._id = action.payload._id;
      state.active = action.payload.active;
      state.auditing = action.payload.auditing;
      state.imported = action.payload.imported;
      state.password_updated = action.payload.password_updated;
      state.enable_maintenance_web = action.payload.enable_maintenance_web;
      state.name = action.payload.name;
      state.login = action.payload.login;
      state.email = action.payload.email;
      state.group = action.payload.group;
      state.role = action.payload.role;
      state.registration = action.payload.registration;
      state.auditing_areas = action.payload.auditing_areas;
      state.createdAt = action.payload.createdAt;
      state.updatedAt = action.payload.updatedAt;
      state.__v = action.payload.__v;
      state.auditing_areas_descr = action.payload.auditing_areas_descr;
      state.id = action.payload.id;
    },
    clearUser(state) {
      state._id = initialState._id;
      state.active = initialState.active;
      state.auditing = initialState.auditing;
      state.imported = initialState.imported;
      state.password_updated = initialState.password_updated;
      state.enable_maintenance_web = initialState.enable_maintenance_web;
      state.name = initialState.name;
      state.login = initialState.login;
      state.email = initialState.email;
      state.group = initialState.group;
      state.role = initialState.role;
      state.registration = initialState.registration;
      state.auditing_areas = initialState.auditing_areas;
      state.createdAt = initialState.createdAt;
      state.updatedAt = initialState.updatedAt;
      state.__v = initialState.__v;
      state.auditing_areas_descr = initialState.auditing_areas_descr;
      state.id = initialState.id;
    },
  },
});

export const { configureUser, clearUser } = userSlicer.actions;

export const userReducer = userSlicer.reducer;
