import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import { ServiceCardProps } from '../ServiceCard';
import moment from 'moment';
import useFetch from '../../hooks/useFetch';
import { editService } from '../../services/api';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ConfirmationModal from '../ConfirmationModal';
import ChangeBaseModal, { ChangeBaseProps } from '../ChangeBaseModal';
import useLogin from '../../hooks/useLogin';
import useBases from '../../hooks/useBaseServices';
import { BaseProps } from '../../store/slices/bases';

type RequestMosaModalProps = {
  service: ServiceCardProps;
  open: boolean;
  setOpen: (open: boolean) => void;
  setService: any;
};

const useStyles = makeStyles((theme) => ({
  listItemWrapper: {
    padding: 0,
  },
  listItemText: {
    '& span': {
      fontSize: theme.typography.body2.fontSize,
      color: theme.palette.primary.dark,
    },
    '& p': {
      fontSize: theme.typography.body2.fontSize,
      color: theme.palette.text.primary,
    },
  },
}));

const RequestMosaModal = ({
  service,
  open,
  setOpen,
  setService,
}: RequestMosaModalProps) => {
  const classes = useStyles();
  const [confirmMosaModal, setConfirmMosaModal] = useState(false);
  const [changeBaseModalOpened, setChangeBaseModalOpened] = useState(false);
  const [changeBaseModalValue, setChangeBaseModalValue] =
    useState<ChangeBaseProps>({
      base: service.base?.id,
      hangar: service.hangar?.id,
      position: service.position,
    });
  const [bases, setBases] = useState<BaseProps[]>([]);
  const {
    user: { id },
    token,
  } = useLogin();
  const {
    data: mosaData,
    error: mosaError,
    apiRequest: mosaRequest,
  } = useFetch();
  const {
    data: changeBaseData,
    error: changeBaseError,
    apiRequest: changeBaseRequest,
  } = useFetch();
  const { basesData, basesLoading } = useBases();

  const handleClose = useCallback(() => {
    setOpen(false);
    setConfirmMosaModal(false);
    setChangeBaseModalOpened(false);
  }, [setOpen]);

  useEffect(() => {
    if (basesData) {
      setBases(basesData);
    }
  }, [basesData]);

  // Lidar com erros
  useEffect(() => {
    if (mosaData.length !== 0) {
      setService(null);
      toast.success('Operação realizada com sucesso');
      handleClose();
    } else if (changeBaseData.length !== 0) {
      setService(null);
      toast.success('Operação realizada com sucesso');
      handleClose();
    } else if (mosaError || changeBaseError) {
      toast.error(
        'Ocorreu um erro durante a operação, tente novamente mais tarde.'
      );
      handleClose();
    }
  }, [
    mosaData,
    mosaError,
    changeBaseError,
    changeBaseData,
    handleClose,
    setService,
  ]);

  // Solicitar MOSA
  function handleConfirmMosaRequest() {
    mosaRequest(
      editService({
        service: {
          ...service,
          mosa_status: 'MOSA Solicitada pela Manutenção',
          isMosa: true,
        },
        userId: id,
        token,
      })
    );
  }

  function handleChangeBase() {
    changeBaseRequest(
      editService({
        service: {
          ...service,
          base: { _id: changeBaseModalValue.base },
          hangar: { _id: changeBaseModalValue.hangar },
          position: changeBaseModalValue.position,
        },
        userId: id,
        token,
      })
    );
  }

  return (
    <>
      <Dialog
        open={open}
        scroll='paper'
        fullWidth
        maxWidth='sm'
        onClose={handleClose}
      >
        <DialogTitle>Gerenciar serviço</DialogTitle>
        <DialogContent dividers>
          <List>
            <ListItem className={classes.listItemWrapper}>
              <ListItemText
                primary='Aeronave'
                secondary={
                  service.aircraft?._model
                    ? `${service.aircraft?._model.model} - ${service.aircraft?.prefix}`
                    : '-'
                }
                className={classes.listItemText}
              />
            </ListItem>
            <ListItem className={classes.listItemWrapper}>
              <ListItemText
                primary='Código mosa'
                secondary={service.code ? `${service.code}` : '-'}
                className={classes.listItemText}
              />
            </ListItem>
            <ListItem className={classes.listItemWrapper}>
              <ListItemText
                primary='Base'
                secondary={service.base?.name ? `${service.base.name}` : '-'}
                className={classes.listItemText}
              />
            </ListItem>
            <ListItem className={classes.listItemWrapper}>
              <ListItemText
                primary='Inspeção'
                secondary={service.inspection ? `${service.inspection}` : '-'}
                className={classes.listItemText}
              />
            </ListItem>
            <ListItem className={classes.listItemWrapper}>
              <ListItemText
                primary='Hangar'
                secondary={
                  service.hangar
                    ? `${service.hangar.code} - Posição ${service.position}`
                    : 'Não definido'
                }
                className={classes.listItemText}
              />
            </ListItem>
            <ListItem className={classes.listItemWrapper}>
              <ListItemText
                primary='Início do serviço'
                secondary={
                  service.start_service
                    ? `${moment(service.start_service).format(
                        'DD/MM/Y [às] HH:mm'
                      )}`
                    : 'Não definido'
                }
                className={classes.listItemText}
              />
            </ListItem>
            <ListItem className={classes.listItemWrapper}>
              <ListItemText
                primary='Fim do serviço'
                secondary={
                  service.end_service
                    ? `${moment(service.end_service).format(
                        'DD/MM/Y [às] HH:mm'
                      )}`
                    : 'Não definido'
                }
                className={classes.listItemText}
              />
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='default'>
            Voltar
          </Button>
          <Button
            onClick={() => setChangeBaseModalOpened(true)}
            color='secondary'
            variant='contained'
            style={{ backgroundColor: '#4EAE57' }}
          >
            Alterar base
          </Button>
          <Button
            onClick={() => setConfirmMosaModal(true)}
            color='primary'
            variant='contained'
          >
            Solicitar MOSA
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmationModal
        open={confirmMosaModal}
        message='Você realmente deseja solicitar MOSA para este serviço? Esta ação não pode ser desfeita.'
        onClose={() => setConfirmMosaModal(false)}
        onConfirm={handleConfirmMosaRequest}
      />
      {service?._id && (
        <ChangeBaseModal
          open={changeBaseModalOpened}
          setChangeBaseModalValue={setChangeBaseModalValue}
          onClose={() => setChangeBaseModalOpened(false)}
          onConfirm={handleChangeBase}
          baseId={service._id}
          bases={bases}
          basesLoading={basesLoading}
        />
      )}
    </>
  );
};

export default RequestMosaModal;
