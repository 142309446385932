import { useSelector } from 'react-redux';
import { StoreTypes } from '../store/configureStore';

const useNotifications = () => {
  const {
    data: notificationsData,
    error: notificationsError,
    loading: notificationsLoading,
  } = useSelector((store: StoreTypes) => store.notifications);
  return { notificationsData, notificationsError, notificationsLoading };
};

export default useNotifications;
