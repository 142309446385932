import { Paper, Typography } from '@material-ui/core';
import { FC } from 'react';
import { ReviewServiceProps } from '../../../pages/Mosa/index.d';
import { useStyles } from '../styles';

const Events: FC<ReviewServiceProps> = ({ data }) => {
  const classes = useStyles();

  return (
    <>
      <Paper className={classes.root}>
        <Typography variant="body1" className={classes.sectionTitle}>
          VIII – Eventos Observados
        </Typography>
        <table className={classes.table}>
          <thead>
            <tr>
              <th>Eventos</th>
            </tr>
          </thead>
          <tbody>
            {!data.events || data.events.length === 0 ? (
              <tr>
                <td>Nenhum evento observado</td>
              </tr>
            ) : (
              data.events.map((event) => (
                <tr key={event.description}>
                  <td>
                    <p>
                      <span className={classes.sectionTitle}>Ameaça: </span>
                      {event.description}
                    </p>
                    <p>
                      <span className={classes.sectionTitle}>
                        Gerenciamento:{' '}
                      </span>
                      {event.management}
                    </p>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </Paper>
    </>
  );
};

export default Events;
