import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { useEffect } from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { DenyMosaProps } from '../ManageMosaModal';
import ConfirmationModal from '../ConfirmationModal';
import useReasons from '../../hooks/useReasons';
import { ReasonProps } from '../../store/slices/reasons';

type DenyMosaModalProps = {
  open: boolean;
  setDenyMosaModalValue: any;
  onClose: () => void;
  onConfirm: () => void;
};

const DenyMosaModal = ({
  open,
  setDenyMosaModalValue,
  onClose,
  onConfirm,
}: DenyMosaModalProps) => {
  const [reasons, setReasons] = useState<ReasonProps[]>();
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [observations, setObservations] = useState<string>();
  const [selectedReason, setSelectedReason] = useState<string>();
  const [selectedReasonDescription, setSelectedReasonDescription] =
    useState<string>();
  const { reasonsData, reasonsError, reasonsLoading } = useReasons();

  // Popula os hangares e lida com possíveis erros
  useEffect(() => {
    if (reasonsError) {
      toast.error(
        'Ocorreu um erro durante a operação. Tente novamente mais tarde'
      );
    } else if (reasonsData) {
      setReasons(reasonsData);
    }
  }, [reasonsData, reasonsError]);

  useEffect(() => {
    if (selectedReason) {
      setDenyMosaModalValue((oldValue: DenyMosaProps) => ({
        ...oldValue,
        reason: selectedReason,
      }));
    }
    if (observations) {
      setDenyMosaModalValue((oldValue: DenyMosaProps) => ({
        ...oldValue,
        observation: observations,
      }));
    }
  }, [selectedReason, observations, setDenyMosaModalValue]);

  // Altera a descrição quando muda o motivo
  useEffect(() => {
    if (reasonsData && selectedReason) {
      const findedReason = reasonsData.find(
        (reason: ReasonProps) => reason._id === selectedReason
      );
      if (findedReason) setSelectedReasonDescription(findedReason.description);
    } else if (selectedReason === '') {
      setSelectedReasonDescription(undefined);
    }
  }, [reasonsData, selectedReason]);

  return (
    <>
      <Dialog open={open} fullWidth maxWidth='sm' onClose={onClose}>
        <DialogTitle>
          Selecione um motivo para a não ocorrência da MOSA
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <InputLabel id='reasons'>Motivos</InputLabel>
            <Select
              labelId='reasons'
              onChange={(event) =>
                setSelectedReason(event.target.value as string)
              }
              value={selectedReason}
              disabled={reasonsLoading}
            >
              <MenuItem value=''>Selecione um motivo</MenuItem>
              {reasons &&
                reasons.map((reason) => (
                  <MenuItem value={reason._id} key={reason._id}>
                    {reason.title}
                  </MenuItem>
                ))}
            </Select>
            <FormHelperText>
              {selectedReasonDescription && selectedReasonDescription}
            </FormHelperText>
          </FormControl>
          <FormControl fullWidth>
            <TextField
              label='Observações'
              value={observations}
              onChange={(event) =>
                setObservations(event.target.value as string)
              }
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color='secondary' variant='contained'>
            Cancelar
          </Button>
          <Button
            onClick={() => setConfirmationModal(true)}
            color='primary'
            variant='contained'
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmationModal
        open={confirmationModal}
        onClose={() => setConfirmationModal(false)}
        onConfirm={onConfirm}
        message='Deseja realmente enviar o relatório? Novas alterações não poderão ser realizadas após esta confirmação.'
      />
    </>
  );
};

export default DenyMosaModal;
