import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type TokenProps = {
  token: string;
};

const initialState: TokenProps = {
  token: '',
};

const tokenSlicer = createSlice({
  name: 'token',
  initialState,
  reducers: {
    configureToken(state, action: PayloadAction<TokenProps>) {
      state.token = action.payload.token;
    },
    clearToken(state) {
      state.token = initialState.token;
    },
  },
});

export const { configureToken, clearToken } = tokenSlicer.actions;

export const tokenReducer = tokenSlicer.reducer;
