import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { useEffect } from 'react';
import { useState } from 'react';
import useFetch from '../../hooks/useFetch';
import { findAllHangars, HangarProps } from '../../services/api';
import { toast } from 'react-toastify';
import useLogin from '../../hooks/useLogin';
import { BaseProps } from '../../store/slices/bases';

export type ChangeBaseProps = {
  base?: string;
  hangar?: string;
  position?: number;
};

type ChangeBaseModalProps = {
  open: boolean;
  setChangeBaseModalValue: (arg: ChangeBaseProps) => void;
  onClose: () => void;
  onConfirm: () => void;
  bases: BaseProps[];
  basesLoading: boolean;
  baseId: string;
};

const ChangeBaseModal = ({
  open,
  setChangeBaseModalValue,
  onClose,
  onConfirm,
  baseId,
  bases,
  basesLoading,
}: ChangeBaseModalProps) => {
  const { token } = useLogin();
  const [selectedBase, setSelectedBase] = useState(baseId);
  const [selectedPosition, setSelectedPosition] = useState<number>();
  const [hangar, setHangar] = useState<HangarProps>();
  const {
    data: hangarData,
    loading: hangarLoading,
    error: hangarError,
    apiRequest: hangarApi,
  } = useFetch();

  // Carrega os hangares e as posições da api
  useEffect(() => {
    if (token) hangarApi(findAllHangars(token, selectedBase));
  }, [token, selectedBase, hangarApi]);

  // Popula os hangares e lida com possíveis erros
  useEffect(() => {
    if (hangarError) {
      toast.error(
        'Ocorreu um erro durante a operação. Tente novamente mais tarde'
      );
    } else if (hangarData.data?.length > 0) {
      setHangar(hangarData.data[0]);
    }
  }, [hangarData, hangarError]);

  // Modifica a base de acordo com o valor selecionado
  useEffect(() => {
    setChangeBaseModalValue({
      position: selectedPosition,
      base: selectedBase,
      hangar: hangar?._id,
    });
  }, [selectedBase, selectedPosition, hangar, setChangeBaseModalValue]);

  return (
    <Dialog open={open} fullWidth maxWidth='sm' onClose={onClose}>
      <DialogTitle>Alterar base</DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          <InputLabel id='bases'>Bases</InputLabel>
          <Select
            labelId='bases'
            onChange={(event) => setSelectedBase(event.target.value as string)}
            value={selectedBase}
            disabled={basesLoading}
          >
            <MenuItem value=''>Selecione uma base</MenuItem>
            {bases.map((base) => (
              <MenuItem value={base._id} key={base._id}>
                {base.name} ({base.code})
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id='positions'>Posição</InputLabel>
          <Select
            labelId='positions'
            onChange={(event) =>
              setSelectedPosition(Number(event.target.value))
            }
            value={selectedPosition || 999}
            disabled={hangarLoading}
          >
            <MenuItem value={999}>Selecione uma posição</MenuItem>
            {hangar &&
              hangar.positions &&
              hangar.positions.map((position, index: number) => (
                <MenuItem
                  value={position.position}
                  key={`${hangar.code} - Posição ${position.position} }`}
                >
                  {hangar.code} - Posição {position.position}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='secondary' variant='contained'>
          Cancelar
        </Button>
        <Button onClick={onConfirm} color='primary' variant='contained'>
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeBaseModal;
