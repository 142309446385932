import { useSelector } from 'react-redux';
import { StoreTypes } from '../store/configureStore';

const useReasons = () => {
  const {
    data: reasonsData,
    error: reasonsError,
    loading: reasonsLoading,
  } = useSelector((store: StoreTypes) => store.reasons);
  return { reasonsData, reasonsError, reasonsLoading };
};

export default useReasons;
