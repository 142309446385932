import React from 'react';
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  RadioGroup,
  Radio,
  FormControlLabel,
} from '@material-ui/core';
import { AircraftModelProps } from '../../store/slices/aircraftModels';

export interface FilterModalListProps {
  keepMounted: boolean;
  value: string;
  open: boolean;
  onClose: (value?: string) => void;
  data: AircraftModelProps[];
}

const FilterModalList = ({
  onClose,
  value: valueProp,
  open,
  data,
}: FilterModalListProps) => {
  const [value, setValue] = React.useState(valueProp);
  const radioGroupRef = React.useRef<HTMLElement>(null);

  React.useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onClose(value);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  return (
    <Dialog
      fullWidth
      maxWidth='sm'
      aria-labelledby='Modal de seleção de filtro'
      open={open}
      onEntering={handleEntering}
      style={{
        margin: '0 auto',
      }}
    >
      <DialogTitle>Modelos de aeronave</DialogTitle>
      <DialogContent dividers>
        <RadioGroup ref={radioGroupRef} value={value} onChange={handleChange}>
          <FormControlLabel
            value='all'
            control={<Radio />}
            label='Selecionar todas os modelos de aeronave'
          />
          {data.map((aircraft) => (
            <FormControlLabel
              value={aircraft._id}
              key={aircraft._id}
              control={<Radio />}
              label={`${aircraft.manufacturer} - ${aircraft.model}`}
            />
          ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color='primary'>
          Cancelar
        </Button>
        <Button onClick={handleOk} color='primary'>
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FilterModalList;
