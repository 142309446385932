import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_URL } from '../../services/api';
import { AppDispatch } from '../configureStore';

export type BaseProps = {
  _id: string;
  name: string;
  code: string;
};

type BasesSliceProps = {
  loading: boolean;
  data: BaseProps[] | null;
  error: string | null;
};

const initialState: BasesSliceProps = {
  loading: false,
  data: null,
  error: null,
};

const basesSlicer = createSlice({
  name: 'bases',
  initialState,
  reducers: {
    fetchStarted(state) {
      state.loading = true;
    },
    fetchSuccess(state, action: PayloadAction<BaseProps[]>) {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    },
    fetchError(state, action: PayloadAction<string>) {
      state.loading = false;
      state.data = null;
      state.error = action.payload;
    },
  },
});

export const { fetchStarted, fetchSuccess, fetchError } = basesSlicer.actions;

export const fetchBases = (token: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(fetchStarted());
    const header = new Headers();
    header.append('Content-Type', 'application/json');
    header.append('Authorization', `Bearer ${token}`);
    const response = await fetch(API_URL + '/bases-listall-services', {
      method: 'GET',
      headers: header,
    });
    const jsonResponse = (await response.json()) as BaseProps[];
    dispatch(fetchSuccess(jsonResponse));
  } catch (error) {
    return dispatch(fetchError(error.message));
  }
};

export const basesReducer = basesSlicer.reducer;
