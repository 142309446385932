import {
  Button,
  CircularProgress,
  makeStyles,
  Paper,
  SvgIcon,
  Typography,
} from '@material-ui/core';
import {
  Room as BaseIcon,
  Build as MOSAIcon,
  BuildOutlined as NonMOSAIcon,
  KeyboardArrowLeft as PrevIcon,
  KeyboardArrowRight as NextIcon,
} from '@material-ui/icons';
import Header from '../../components/Header';
import CardBG from '../../assets/images/background-card.png';
import { FC, useEffect, useState } from 'react';
import { getBases } from '../../services/api';
import { Link } from 'react-router-dom';
import useFetch from '../../hooks/useFetch';
import useLogin from '../../hooks/useLogin';
import useNotifications from '../../hooks/useNotifications';
import { NotificationProps } from '../../store/slices/notifications';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  card: {
    backgroundImage: `url("${CardBG}")`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'right bottom',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  link: {
    textDecoration: 'none',
  },
  cardTitle: {
    color: theme.palette.primary.contrastText,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    '& span': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    '& .base-icon': {
      fontSize: 40,
    },
    '& .base-name-icon': {
      fontSize: 20,
    },
    '& .code-text': {
      fontWeight: 'bold',
    },
  },
  MOSAServices: {
    display: 'flex',
    alignContent: 'center',
    color: '#ffc107',
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
  NonMOSAServices: {
    display: 'flex',
    alignContent: 'center',
    color: theme.palette.primary.contrastText,
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

type Base = {
  active: boolean;
  code: string;
  mosa_services: number;
  name: string;
  services_count: number;
  _id: string;
};

const Home = () => {
  const [mosaCountNotification, setMosaCountNotification] =
    useState<string>('');
  const classes = useStyles();
  const { user, token } = useLogin();
  const {
    notificationsData: notifications,
    notificationsLoading: loadingNotifications,
  } = useNotifications();
  const {
    data: bases,
    loading: loadingBases,
    apiRequest: basesRequest,
  } = useFetch({ page: 0, pages: 0, result: [], total: 0 });

  useEffect(() => {
    if (!notifications) return;

    const inspectionsItem = notifications.find(
      ({ type }: NotificationProps) => type === 'Inspection'
    );
    if (inspectionsItem?.value) setMosaCountNotification(inspectionsItem.value);
  }, [notifications]);

  function handleGetBases(page: number) {
    if (token) basesRequest(getBases(user.id, page, token));
  }

  function handlePrevPage() {
    handleGetBases(bases.page - 1);
  }
  function handleNextPage() {
    handleGetBases(bases.page + 1);
  }

  useEffect(() => {
    if (!user.id || !token) return;
    handleGetBases(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, token, basesRequest]);

  return (
    <>
      <Header showProfileButton />
      <div className={classes.root}>
        {!loadingBases && (
          <Link to='/services'>
            <Button fullWidth color='primary'>
              {loadingNotifications ? (
                <CircularProgress color='primary' />
              ) : (
                mosaCountNotification
              )}
            </Button>
          </Link>
        )}
        {!loadingBases &&
          bases?.result &&
          bases.result.map((base: Base) => <Card key={base._id} base={base} />)}
        {loadingBases && (
          <div className={classes.spinner}>
            <CircularProgress />
          </div>
        )}
        {!loadingBases && (
          <span className={classes.buttonGroup}>
            <Button
              disabled={bases.page <= 1}
              color='primary'
              startIcon={<PrevIcon />}
              onClick={handlePrevPage}
            >
              Página Anterior
            </Button>
            <Button
              color='primary'
              disabled={bases.page >= bases.pages}
              endIcon={<NextIcon />}
              onClick={handleNextPage}
            >
              Próxima Página
            </Button>
          </span>
        )}
      </div>
    </>
  );
};

const Card: FC<{ base: Base }> = ({ base }) => {
  const classes = useStyles();
  return (
    <Link to={`/services/${base._id}`}>
      <Paper className={classes.card}>
        <span className={classes.cardTitle}>
          <span>
            <SvgIcon className='base-icon'>
              <svg viewBox='0 0 24 24'>
                <path
                  fill='currentColor'
                  d='M19,20H17V11H7V20H5V9L12,5L19,9V20M8,12H16V14H8V12M8,15H16V17H8V15M16,18V20H8V18H16Z'
                />
              </svg>
            </SvgIcon>
            <Typography variant='h5' className='code-text' component='span'>
              {base.code}
            </Typography>
          </span>
          <span>
            <BaseIcon className='base-name-icon' />{' '}
            <Typography variant='subtitle1' component='span'>
              {base.name}
            </Typography>
          </span>
        </span>
        <span className={classes.MOSAServices}>
          <MOSAIcon />
          <Typography component='span' variant='body1'>
            {base.mosa_services} Serviços MOSA
          </Typography>
        </span>
        <span className={classes.NonMOSAServices}>
          <NonMOSAIcon />
          <Typography component='span' variant='body2'>
            {base.services_count} Serviços Não MOSA
          </Typography>
        </span>
      </Paper>
    </Link>
  );
};

export default Home;
