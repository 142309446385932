import { Paper, Typography, TextField } from '@material-ui/core';
import { FC } from 'react';
import { ServiceProps } from '../../../pages/Mosa/index.d';
import { useStyles } from '../styles';

const Comments: FC<ServiceProps> = ({ data, onChangeDate, onChange }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Typography variant="h6" className={classes.title}>
        VI – Comentários do Auditor
      </Typography>
      <TextField
        label="Pontos Positivos"
        name="good_points"
        value={data?.good_points || null}
        onChange={onChange}
        fullWidth
        className={classes.input}
        multiline
      />
      <TextField
        label="Pontos Negativos"
        name="bad_points"
        value={data?.bad_points || null}
        onChange={onChange}
        fullWidth
        className={classes.input}
        multiline
      />
      <TextField
        label="Comentários Gerais"
        name="comments"
        value={data?.comments || null}
        onChange={onChange}
        fullWidth
        className={classes.input}
        multiline
      />
    </Paper>
  );
};

export default Comments;
