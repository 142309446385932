import {
  AppBar,
  IconButton,
  makeStyles,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { useState } from 'react';
import FilterListIcon from '@material-ui/icons/FilterList';
import GoBackIcon from '@material-ui/icons/ArrowBack';
import { Link, useHistory } from 'react-router-dom';
import FilterModal, { FilterProps } from '../FilterModal';
import LogoImg from '../../assets/images/logo.png';

const useStyles = makeStyles((theme) => ({
  toolbarMosa: {
    justifyContent: 'space-between',
  },
  toolbarNonMosa: {
    justifyContent: 'space-between',
    backgroundColor: theme.palette.background.default,
  },
  logoWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& img': {
      width: 90,
    },
  },
  title: {
    color: theme.palette.text.primary,
  },
  iconButtonMosa: {
    color: theme.palette.primary.contrastText,
  },
  iconButtonNonMosa: {
    color: theme.palette.text.primary,
  },
}));

type ServicesHeaderProps = {
  mosa?: boolean;
  setFilter: (state: FilterProps) => void;
};

const ServicesHeader = ({ mosa, setFilter }: ServicesHeaderProps) => {
  const classes = useStyles();
  const history = useHistory();
  const [modalOpened, setModalOpened] = useState(false);

  function handleGoBack() {
    history.goBack();
  }

  function handleOpenFilterModal() {
    setModalOpened(true);
  }

  return (
    <>
      <AppBar position='static'>
        <Toolbar
          className={mosa ? classes.toolbarMosa : classes.toolbarNonMosa}
        >
          <IconButton
            className={
              mosa ? classes.iconButtonMosa : classes.iconButtonNonMosa
            }
            onClick={handleGoBack}
          >
            <GoBackIcon />
          </IconButton>
          {mosa ? (
            <Link className={classes.logoWrapper} to='/'>
              <img src={LogoImg} alt='Omni' />
            </Link>
          ) : (
            <Typography variant='h6' className={!mosa && classes.title}>
              Serviços não MOSA
            </Typography>
          )}

          <IconButton
            className={
              mosa ? classes.iconButtonMosa : classes.iconButtonNonMosa
            }
            onClick={handleOpenFilterModal}
          >
            <FilterListIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <FilterModal
        open={modalOpened}
        setOpen={setModalOpened}
        setFilter={setFilter}
      />
    </>
  );
};

export default ServicesHeader;
