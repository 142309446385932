import { Redirect, Route } from 'react-router-dom';
import useLogin from '../../hooks/useLogin';

type ProtectedRouteProps = {
  component: JSX.Element;
  path: string;
  exact?: boolean;
};

function ProtectedRoute({
  component,
  path,
  exact,
  ...rest
}: ProtectedRouteProps) {
  const { isLogged } = useLogin();
  return (
    <>
      {isLogged ? (
        <Route
          {...rest}
          path={path}
          exact={exact}
          component={() => component}
        />
      ) : (
        <Redirect to='/login' />
      )}
    </>
  );
}

export default ProtectedRoute;
