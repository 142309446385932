import { Paper, Typography } from '@material-ui/core';
import { FC, useEffect } from 'react';
import useFetch from '../../../hooks/useFetch';
import useLogin from '../../../hooks/useLogin';
import { ReviewServiceProps } from '../../../pages/Mosa/index.d';
import { getProcesses } from '../../../services/api';
import { useStyles } from '../styles';

type ProcessType = {
  _id: string;
  type: string;
  code: string;
  description: string;
};

const Process: FC<ReviewServiceProps> = ({ data }) => {
  const classes = useStyles();
  const {
    data: { data: processes },
    apiRequest,
  } = useFetch();
  const { token } = useLogin();

  useEffect(() => {
    apiRequest(getProcesses({ token }));
  }, [apiRequest, token]);

  const process =
    data.process && processes
      ? processes.find((proc: ProcessType) => proc._id === data.process)
      : {};

  return (
    <>
      <Paper className={classes.root}>
        <Typography variant='body1' className={classes.sectionTitle}>
          III – Processo Observado
        </Typography>
        <Typography variant='caption' className={classes.title}>
          Processo Observado
        </Typography>
        <Typography variant='body1' className={classes.value}>
          {process.code && process.description && (
            <>
              {process.code} - {process.description}
            </>
          )}
        </Typography>
        <Typography variant='caption' className={classes.title}>
          Produtos
        </Typography>
        <table className={classes.table}>
          <thead>
            <tr>
              <th>Produtos - {data?.product_type}</th>
            </tr>
          </thead>
          <tbody>
            {!data.products || data.products.length === 0 ? (
              <tr>
                <td>Nenhum produto ou atividade</td>
              </tr>
            ) : (
              data.products.map((product) => (
                <tr key={`${product.ns}${product.pn}`}>
                  <td>
                    <p>
                      <span className={classes.sectionTitle}>
                        PN ou Modelo:{' '}
                      </span>
                      {product.pn}
                    </p>
                    <p>
                      <span className={classes.sectionTitle}>
                        Matrícula/NS:{' '}
                      </span>
                      {product.ns}
                    </p>
                    <p>
                      <span className={classes.sectionTitle}>Descrição: </span>
                      {product.description}
                    </p>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </Paper>
    </>
  );
};
export default Process;
