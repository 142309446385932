import { useCallback, useState } from 'react';

const useFetch = (initialDataState: any = []) => {
  const [data, setData] = useState(initialDataState);
  const [error, setError] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);

  const apiRequest = useCallback(async (apiFunction: Function) => {
    try {
      setLoading(true);
      const apiData = await apiFunction();
      if (!apiData?.ok || apiData?.status === '400') {
        throw new Error(apiData.message || 'Ocorreu um erro. Tente novamente mais tarde!');
      } else {
        setData(apiData);
      }
    } catch (err) {
      setError(err.message);
      setData([]);
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    data,
    error,
    loading,
    apiRequest,
    setData,
    setLoading,
    setError,
  };
};

export default useFetch;
