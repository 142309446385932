import {
  AppBar,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Popover,
  Toolbar,
} from '@material-ui/core';
import {
  AccountCircle as UserIcon,
  Person as ProfileIcon,
  ExitToApp as LogoutIcon,
} from '@material-ui/icons';
import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import Logo from '../../assets/images/logo.png';
import { clearToken } from '../../store/slices/token';
import { clearUser } from '../../store/slices/user';
import GoBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles((theme) => ({
  logoWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  goBackIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.contrastText,
  },
  logo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& img': {
      width: 90,
    },
  },
  userMenuButton: {
    color: theme.palette.primary.contrastText,
  },
  toolbar: {
    justifyContent: 'space-between',
  },
  userMenuPopover: {
    overflow: 'visible',
    marginLeft: 15,
    '&:before': {
      content: '""',
      border: '10px solid transparent',
      borderBottomColor: 'white',
      position: 'absolute',
      right: 5,
      top: -20,
    },
  },
}));

type HeaderProps = {
  showProfileButton?: boolean;
  showGoBackIcon?: boolean;
};

const Header = ({ showProfileButton, showGoBackIcon }: HeaderProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const classes = useStyles();

  const userMenuButton = useRef(null);

  function handleCloseUserMenu() {
    setUserMenuOpen(false);
  }

  function handleOpenUserMenu() {
    setUserMenuOpen(true);
  }

  function handleAccessProfilePage() {
    history.push('/profile');
  }

  function handleLogout() {
    dispatch(clearUser());
    dispatch(clearToken());
    localStorage.removeItem('token');
    localStorage.removeItem('user');
  }

  return (
    <AppBar position='static'>
      <Toolbar className={classes.toolbar}>
        <div className={classes.logoWrapper}>
          {showGoBackIcon && (
            <IconButton
              className={classes.goBackIcon}
              onClick={() => history.push('/')}
            >
              <GoBackIcon />
            </IconButton>
          )}
          <Link
            className={classes.logo}
            to='/'
            style={showProfileButton ? {} : { width: '100%' }} // Centralizar o logo na página de usuário
          >
            <img src={Logo} alt='Omni' />
          </Link>
        </div>
        {showProfileButton && (
          <>
            <IconButton
              className={classes.userMenuButton}
              ref={userMenuButton}
              onClick={handleOpenUserMenu}
            >
              <UserIcon />
            </IconButton>
            <Popover
              open={userMenuOpen}
              onClose={handleCloseUserMenu}
              anchorEl={userMenuButton.current}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              classes={{ paper: classes.userMenuPopover }}
            >
              <List>
                <ListItem button onClick={handleAccessProfilePage}>
                  <ListItemIcon>
                    <ProfileIcon />
                  </ListItemIcon>
                  <ListItemText>Perfil</ListItemText>
                </ListItem>
                <ListItem button onClick={handleLogout}>
                  <ListItemIcon>
                    <LogoutIcon />
                  </ListItemIcon>
                  <ListItemText>Sair</ListItemText>
                </ListItem>
              </List>
            </Popover>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
