import { useSelector } from 'react-redux';
import { StoreTypes } from '../store/configureStore';

const useAircraftModels = () => {
  const {
    data: aircraftModelsData,
    error: aircraftModelsError,
    loading: aircraftModelsLoading,
  } = useSelector((store: StoreTypes) => store.aircraftModels);
  return { aircraftModelsData, aircraftModelsError, aircraftModelsLoading };
};

export default useAircraftModels;
