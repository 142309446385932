import { UserProps } from '../store/slices/user';
import { Service } from '../pages/Mosa/index.d';
import fetch from './fetch';
import { ServiceCardProps } from '../components/ServiceCard';
import { BaseProps } from '../store/slices/bases';

export const API_URL = process.env.REACT_APP_API_URL;

export async function loginUser(login: string, password: string) {
  const header = new Headers();
  header.append('Content-Type', 'application/json');

  const response = await fetch(`${API_URL}/login`, {
    method: 'POST',
    headers: header,
    body: JSON.stringify({ login, password }),
  });
  const jsonResponse = await response.json();
  return { ...jsonResponse, ok: response.ok };
}

export async function authUser(token: string) {
  const header = new Headers();
  header.append('Content-Type', 'application/json');
  header.append('Authorization', `Bearer ${token}`);

  const response = await fetch(`${API_URL}/authenticate`, {
    method: 'GET',
    headers: header,
  });
  const jsonResponse = await response.json();
  return { ...jsonResponse, ok: response.ok };
}

export function getBases(userId: string, page: number, token: string) {
  return async () => {
    const header = new Headers();
    header.append('Content-Type', 'application/json');
    header.append('Authorization', `Bearer ${token}`);

    const response = await fetch(
      `${API_URL}/bases-listall/${page}?user_id=${userId}&limit=10`,
      {
        method: 'GET',
        headers: header,
      }
    );

    const jsonResponse = await response.json();
    return { status: response.status, ...jsonResponse, ok: response.ok };
  };
}

export async function recoverPassword(email: string) {
  const header = new Headers();
  header.append('Content-Type', 'application/json');

  const response = await fetch(`${API_URL}/forgot-password/`, {
    method: 'POST',
    headers: header,
    body: JSON.stringify({ email }),
  });
  const jsonResponse = await response.json();
  return {
    ...jsonResponse,
    message: 'Um email com as instruções para redefinir a senha foi enviado!',
    ok: response.ok,
  };
}

export type MosaServicesFilterProps = {
  filter: object;
  page: number;
  userId: string;
  token: string;
};

export function findMosaServicesById({
  filter,
  page,
  userId,
  token,
}: MosaServicesFilterProps) {
  return async () => {
    const header = new Headers();
    header.append('Content-Type', 'application/json');
    header.append('Authorization', `Bearer ${token}`);

    const response = await fetch(
      `${API_URL}/pcp_services/filter-inspector-app/${page}`,
      {
        method: 'POST',
        headers: header,
        body: JSON.stringify({ ...filter, id: userId }),
      }
    );
    const jsonResponse = await response.json();
    return { data: jsonResponse, ok: response.ok };
  };
}

export type NotificationsRequest = {
  userId: string;
  token: string;
};

export function getNotifications({ userId, token }: NotificationsRequest) {
  return async () => {
    const header = new Headers();
    header.append('Content-Type', 'application/json');
    header.append('Authorization', `Bearer ${token}`);

    const response = await fetch(`${API_URL}/notifications-central/${userId}`, {
      method: 'GET',
      headers: header,
    });
    const jsonResponse = await response.json();
    return { data: jsonResponse, ok: response.ok };
  };
}

type ServiceRequest = {
  id: string;
  token: string;
};

export function getService({ id, token }: ServiceRequest) {
  return async () => {
    const header = new Headers();
    header.append('Content-Type', 'application/json');
    header.append('Authorization', `Bearer ${token}`);

    const response = await fetch(`${API_URL}/pcp_service/${id}`, {
      method: 'GET',
      headers: header,
    });
    const jsonResponse = await response.json();
    return { data: jsonResponse, ok: response.ok };
  };
}

export type AircraftProps = {
  active: boolean;
  _id: string;
  prefix: string;
  sn: string;
  _model: {
    _id: string;
    manufacturer: string;
    model: string;
  };
};

export function findAllAircrafts(token: string) {
  return async () => {
    const header = new Headers();
    header.append('Content-Type', 'application/json');
    header.append('Authorization', `Bearer ${token}`);
    const response = await fetch(`${API_URL}/aircrafts-listall`, {
      method: 'GET',
      headers: header,
    });
    const jsonResponse = (await response.json()) as AircraftProps;
    return { data: jsonResponse, ok: response.ok };
  };
}

export function getProcesses({ token }: { token: string }) {
  return async () => {
    const header = new Headers();
    header.append('Content-Type', 'application/json');
    header.append('Authorization', `Bearer ${token}`);
    const response = await fetch(`${API_URL}/processes-listall`, {
      method: 'GET',
      headers: header,
    });
    const jsonResponse = await response.json();
    return { data: jsonResponse, ok: response.ok };
  };
}

export function getUsers({ token }: { token: string }) {
  return async () => {
    const header = new Headers();
    header.append('Content-Type', 'application/json');
    header.append('Authorization', `Bearer ${token}`);
    const response = await fetch(`${API_URL}/mechanics-inspectors-listall`, {
      method: 'GET',
      headers: header,
    });
    const jsonResponse = await response.json();
    return { data: jsonResponse, ok: response.ok };
  };
}

export type HangarProps = {
  _id?: string;
  active?: boolean;
  base?: BaseProps;
  code?: string;
  layout?: string;
  name?: string;
  positions?: [
    {
      _id?: string;
      position?: number;
    }
  ];
};

export function findAllHangars(token: string, baseId: string) {
  return async () => {
    const header = new Headers();
    header.append('Content-Type', 'application/json');
    header.append('Authorization', `Bearer ${token}`);

    const response = await fetch(`${API_URL}/hangars/base/${baseId}`, {
      method: 'GET',
      headers: header,
    });
    const jsonResponse = (await response.json()) as HangarProps;
    return { data: jsonResponse, ok: response.ok };
  };
}

export function sendMOSA({ data, token }: { data: Service; token: string }) {
  return fetch(`${API_URL}/pcp_service/${data._id}`, {
    method: 'PUT',
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
    body: JSON.stringify(data),
  });
}
export function sendEmail({ id, token }: { id: string; token: string }) {
  return fetch(`${API_URL}/mosa/send-confirmation/${id}`, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }),
  });
}
export type CreateServiceProps = {
  active?: boolean;
  aircraft?: string;
  base: string;
  circle_credits?: string;
  day_credits?: string;
  end_service?: Date;
  execute_prediction?: Date;
  hangar: string;
  hangarPosition?: {
    hangar?: HangarProps;
    position?: number;
  };
  hour_credits?: string;
  inspection: string;
  isMosa?: boolean;
  man_hour_value?: string;
  mosa_selected_by?: string;
  mosa_status?: string;
  observation?: string;
  position?: number;
  register?: string;
  register_type?: string;
  start_service?: Date;
  status: string;
};

export function createService(token: string, data: CreateServiceProps) {
  return async () => {
    const header = new Headers();
    header.append('Content-Type', 'application/json');
    header.append('Authorization', `Bearer ${token}`);

    const response = await fetch(`${API_URL}/pcp_service`, {
      method: 'POST',
      headers: header,
      body: JSON.stringify({ ...data }),
    });
    const jsonResponse = await response.json();
    return { ...jsonResponse, ok: response.ok };
  };
}

export type NonMosaServicesFilterProps = {
  filter: object;
  page: number;
  userId: string;
  token: string;
};

export function findNonMosaServicesById({
  filter,
  page,
  userId,
  token,
}: NonMosaServicesFilterProps) {
  return async () => {
    const header = new Headers();
    header.append('Content-Type', 'application/json');
    header.append('Authorization', `Bearer ${token}`);

    const response = await fetch(
      `${API_URL}/pcp_services/non-mosa-services/${page}`,
      {
        method: 'POST',
        headers: header,
        body: JSON.stringify({ ...filter, id: userId }),
      }
    );
    const jsonResponse = await response.json();
    return { data: jsonResponse, ok: response.ok };
  };
}

type ServicesByFilter = {
  filter?: {
    aircraft?: string;
    status?: string;
    aircraftModel?: string;
  };
  token: string;
  page: number;
};

export function findServicesByFilter({
  token,
  page,
  filter,
}: ServicesByFilter) {
  return async () => {
    const header = new Headers();
    header.append('Content-Type', 'application/json');
    header.append('Authorization', `Bearer ${token}`);

    const response = await fetch(`${API_URL}/pcp_services/${page}`, {
      method: 'POST',
      headers: header,
      body: JSON.stringify({ ...filter }),
    });
    const jsonResponse = await response.json();
    return { data: jsonResponse, ok: response.ok };
  };
}

type UpdateUserProps = {
  user: UserProps;
  token: string;
};

export function updateUser({ user, token }: UpdateUserProps) {
  return async () => {
    const header = new Headers();
    header.append('Content-Type', 'application/json');
    header.append('Authorization', `Bearer ${token}`);

    const response = await fetch(`${API_URL}/fast_tracking/${user.id}`, {
      method: 'PUT',
      headers: header,
      body: JSON.stringify({ ...user }),
    });
    const jsonResponse = await response.json();
    return { data: jsonResponse, ok: response.ok };
  };
}

type UpdatePasswordProps = {
  userId: string;
  password: string;
  newPassword: string;
  token: string;
};

export function updatePassword({
  userId,
  password,
  newPassword,
  token,
}: UpdatePasswordProps) {
  return async () => {
    const header = new Headers();
    header.append('Content-Type', 'application/json');
    header.append('Authorization', `Bearer ${token}`);

    const response = await fetch(
      `${API_URL}/fast_tracking/change-password/${userId}`,
      {
        method: 'PUT',
        headers: header,
        body: JSON.stringify({ password, newPassword }),
      }
    );
    const jsonResponse = await response.json();
    return { ...jsonResponse, ok: response.ok, status: response.status };
  };
}

export type EditServiceProps = {
  service: ServiceCardProps;
  userId: string;
  token: string;
};

export function editService({ service, userId, token }: EditServiceProps) {
  return async () => {
    const header = new Headers();
    header.append('Content-Type', 'application/json');
    header.append('Authorization', `Bearer ${token}`);

    const response = await fetch(`${API_URL}/pcp_service/${service._id}`, {
      method: 'PUT',
      headers: header,
      body: JSON.stringify({ ...service, id: userId }),
    });
    const jsonResponse = await response.json();
    return { data: jsonResponse, ok: response.ok };
  };
}

export type ReasonsProps = [
  {
    _id?: string;
    active?: boolean;
    description?: string;
    title?: string;
  }
];

export function findAllReasons(token: string) {
  return async () => {
    const header = new Headers();
    header.append('Content-Type', 'application/json');
    header.append('Authorization', `Bearer ${token}`);

    const response = await fetch(`${API_URL}/reasons-listall`, {
      method: 'GET',
      headers: header,
    });
    const jsonResponse = (await response.json()) as ReasonsProps;
    return { data: jsonResponse, ok: response.ok };
  };
}
