import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  makeStyles,
  Paper,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import Header from '../../components/Header';
import {
  Person as PersonIcon,
  VpnKey as ChangePasswordIcon,
  Email as EmailIcon,
  Face as LoginIcon,
  AccountTree as RoleIcon,
  ExitToApp as LoggoutIcon,
  ListAlt as AuditingIcon,
  Edit as EditIcon,
  Event as SinceDateIcon,
} from '@material-ui/icons';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { clearUser, configureUser, UserProps } from '../../store/slices/user';
import useFetch from '../../hooks/useFetch';
import { updatePassword, updateUser } from '../../services/api';
import { clearToken } from '../../store/slices/token';
import { toast } from 'react-toastify';
import useLogin from '../../hooks/useLogin';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(20),
    background: theme.palette.primary.light,
    minHeight: '100vh',
  },
  cardWrapper: {
    maxWidth: '640px',
    margin: '0 auto',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  nameWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  actionsSectionWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  loggoutSectionWrapper: {},
  backdrop: {
    zIndex: theme.zIndex.drawer + 9999,
    color: '#fff',
  },
}));

const Profile = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user: userStore, token } = useLogin();
  const [user, setUser] = useState<UserProps | undefined>(undefined);
  const [firstLoaded, setFirstLoaded] = useState(false); // Referência para prevenir o update de usuário ao entrar na página
  const [editEmailModalOpened, setEditEmailModalOpened] = useState(false);
  const [editEmailModalValue, setEditEmailModalValue] = useState('');
  const [editPasswordModalOpened, setEditPasswordModalOpened] = useState(false);
  const [editPasswordModalValues, setEditPasswordModalValues] = useState({
    password: '',
    newPassword: '',
    passwordConfirmation: '',
  });
  const { loading, apiRequest } = useFetch();
  const {
    data: passData,
    loading: passLoading,
    apiRequest: passApi,
    error: passError,
    setError: setPassError,
  } = useFetch();

  useEffect(() => {
    if (userStore?.id) {
      setUser(userStore);
    }
  }, [userStore]);

  useEffect(() => {
    if (user && user !== userStore && firstLoaded) {
      apiRequest(updateUser({ token, user }))
        .then(() => {
          dispatch(configureUser({ ...user }));
          toast.success('Usuário atualizado com sucesso!');
        })
        .catch((error) => {
          toast.error(error);
        })
        .finally(() => setEditEmailModalOpened(false));
    } else if (!firstLoaded) {
      setFirstLoaded(true);
    }
  }, [user, token, apiRequest, dispatch, firstLoaded, userStore]);

  // Enviar um toast depois da requisição
  useEffect(() => {
    if (passData?.message) {
      toast.success(passData.message);
    }
    if (passError) {
      toast.error(passError);
      setPassError(undefined);
    }
  }, [passData, passError, setPassError]);

  function handleEditPassword() {
    if (
      editPasswordModalValues.newPassword !==
      editPasswordModalValues.passwordConfirmation
    ) {
      toast.error('As senhas não conferem.');
    } else if (user) {
      passApi(
        updatePassword({
          userId: user.id,
          password: editPasswordModalValues.password,
          newPassword: editPasswordModalValues.newPassword,
          token,
        })
      );
      setEditPasswordModalOpened(false);
    }
  }

  function handleToggleAuditing() {
    if (user) {
      setUser({ ...user, auditing: !user.auditing });
    }
  }

  function handleEditEmail() {
    if (user) {
      setUser({ ...user, email: editEmailModalValue });
    }
  }

  function handleLoggout() {
    dispatch(clearUser());
    dispatch(clearToken());
    localStorage.removeItem('token');
    localStorage.removeItem('user');
  }

  return (
    <>
      <Header showGoBackIcon />
      {user?.id && (
        <main className={classes.root}>
          <Paper className={classes.cardWrapper}>
            <section className={classes.nameWrapper}>
              <PersonIcon />
              <Typography variant='subtitle1'>{user.name}</Typography>
            </section>
            <section className={classes.actionsSectionWrapper}>
              <Button
                variant='contained'
                color='primary'
                startIcon={<ChangePasswordIcon />}
                size='small'
                onClick={() => setEditPasswordModalOpened(true)}
              >
                Alterar senha
              </Button>
            </section>
            <section>
              <List
                subheader={
                  <ListSubheader disableSticky>Suas informações</ListSubheader>
                }
              >
                <ListItem>
                  <ListItemIcon>
                    <AuditingIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary='Auditando'
                    secondary='Informe se você está auditando no momento'
                  />
                  <ListItemSecondaryAction>
                    <Switch
                      edge='end'
                      onChange={handleToggleAuditing}
                      checked={user.auditing}
                      inputProps={{
                        'aria-labelledby':
                          'trocar estado de auditor do usuário',
                      }}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <EmailIcon />
                  </ListItemIcon>
                  <ListItemText primary='Seu e-mail' secondary={user.email} />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge='end'
                      aria-label='Editar e-mail'
                      onClick={() => setEditEmailModalOpened(true)}
                    >
                      <EditIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <LoginIcon />
                  </ListItemIcon>
                  <ListItemText primary='Seu login' secondary={user.login} />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <RoleIcon />
                  </ListItemIcon>
                  <ListItemText primary='Seu cargo' secondary={user.role} />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <SinceDateIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary='Usuário desde:'
                    secondary={moment(user.createdAt).format('DD/MM/YYYY')}
                  />
                </ListItem>
              </List>
            </section>
            <section className={classes.loggoutSectionWrapper}>
              <Button
                variant='contained'
                color='secondary'
                fullWidth
                startIcon={<LoggoutIcon />}
                onClick={handleLoggout}
              >
                Sair
              </Button>
            </section>
          </Paper>
        </main>
      )}
      {/* Modal de edição de e-mail */}
      <Dialog
        fullWidth
        maxWidth='sm'
        open={editEmailModalOpened}
        onClose={() => setEditEmailModalOpened(false)}
      >
        <DialogTitle>Alterar e-mail</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin='dense'
            id='new-email'
            label='Novo e-mail'
            type='email'
            value={editEmailModalValue}
            onChange={(e) => setEditEmailModalValue(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => setEditEmailModalOpened(false)}
            color='secondary'
          >
            Cancelar
          </Button>
          <Button onClick={handleEditEmail} color='primary'>
            Alterar e-mail
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth='sm'
        open={editPasswordModalOpened}
        onClose={() => setEditPasswordModalOpened(false)}
      >
        <DialogTitle>Alterar senha</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin='dense'
            id='old-password'
            label='Senha antiga'
            type='password'
            value={editPasswordModalValues.password}
            onChange={(e) =>
              setEditPasswordModalValues({
                ...editPasswordModalValues,
                password: e.target.value,
              })
            }
            fullWidth
          />
          <TextField
            margin='dense'
            id='new-password'
            label='Nova senha'
            type='password'
            value={editPasswordModalValues.newPassword}
            onChange={(e) =>
              setEditPasswordModalValues({
                ...editPasswordModalValues,
                newPassword: e.target.value,
              })
            }
            fullWidth
          />
          <TextField
            margin='dense'
            id='new-password-confirmation'
            label='Confirmação de nova senha'
            type='password'
            value={editPasswordModalValues.passwordConfirmation}
            onChange={(e) =>
              setEditPasswordModalValues({
                ...editPasswordModalValues,
                passwordConfirmation: e.target.value,
              })
            }
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => setEditPasswordModalOpened(false)}
            color='secondary'
          >
            Cancelar
          </Button>
          <Button onClick={handleEditPassword} color='primary'>
            Alterar senha
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop open={loading || passLoading} className={classes.backdrop}>
        <CircularProgress color='inherit' />
      </Backdrop>
    </>
  );
};

export default Profile;
