import store from '../store/configureStore';
import { clearToken } from '../store/slices/token';
import { clearUser } from '../store/slices/user';

const fetchService = async (url: string, params: {}) => {
  const result: any = await fetch(url, params);
  if (result.status !== 401) return result;
  store.dispatch(clearUser());
  store.dispatch(clearToken());
  localStorage.removeItem('token');
  return result;
};

export default fetchService;
