import { Paper, Typography } from '@material-ui/core';
import moment from 'moment';
import { FC } from 'react';
import { ReviewServiceProps } from '../../../pages/Mosa/index.d';
import { useStyles } from '../styles';

const Identification: FC<ReviewServiceProps> = ({ data }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Typography variant="body1" className={classes.sectionTitle}>
        I – Identificação da Auditoria
      </Typography>
      <Typography variant="caption" className={classes.title}>
        Início de MOSA
      </Typography>
      <Typography variant="body1" className={classes.value}>
        {data?.mosa_start
          ? moment(data.mosa_start).format('DD/MM/Y [às] HH:mm')
          : 'Não Definido'}
      </Typography>
      <Typography variant="caption" className={classes.title}>
        Fim de MOSA
      </Typography>
      <Typography variant="body1" className={classes.value}>
        {data?.mosa_end
          ? moment(data.mosa_end).format('HH:mm')
          : 'Não Definido'}
      </Typography>
      <Typography variant="caption" className={classes.title}>
        POSIÇÃO
      </Typography>
      <br />
      <Typography variant="caption" className={classes.title}>
        Tipo
      </Typography>
      <Typography variant="body1" className={classes.value}>
        {data?.position_type}
      </Typography>
      <Typography variant="caption" className={classes.title}>
        Descrição
      </Typography>
      <Typography variant="body1" className={classes.value}>
        {data?.position_description}
      </Typography>
    </Paper>
  );
};

export default Identification;
