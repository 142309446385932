import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    backgroundColor: '#eceff1',
  },
  title: {
    justifyContent: 'center',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    '&.colored': {
      color: theme.palette.primary.dark,
    },
    '&.coloredLight': {
      color: theme.palette.primary.main,
    },
  },
  value: {
    textAlign: 'center',
    marginBottom: theme.spacing(1),
    '&:last-child': {
      marginBottom: 0,
    },
  },
  input: {
    marginTop: theme.spacing(1),
    maxHeight: 100,
    transition: 'all 500ms',
    overflow: 'hidden',
    '&.hidden': {
      transition: 'all 500ms',
      maxHeight: 0,
    },
  },
  card: {
    margin: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    padding: theme.spacing(2),
    display: 'flex',
    '& .subSection': {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
  },
  cardItem: {
    display: 'block',
    borderLeft: `5px solid ${theme.palette.primary.dark}`,
    borderRadius: 5,
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(2),
    '&.light': { borderColor: theme.palette.primary.main },
    '& .label': { fontWeight: 'bold' },
    '&:last-child': {
      marginBottom: 0,
    },
  },
  modalWrapper: {
    display: 'flex',
    flex: 1,
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(1),
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(1),
  },
  editingRow: {
    display: 'flex',
  },
  mechanic: {
    display: 'flex',
    alignItems: 'center',
  },
}));
