import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    backgroundColor: '#fff',
    marginBottom: theme.spacing(1),
  },
  title: {
    color: theme.palette.primary.main,
  },
  sectionTitle: {
    fontWeight: 'bold',
  },
  table: {
    width: '100%',
    '& thead': {
      backgroundColor: '#b0bec5',
    },
    '& tbody tr': {
      backgroundColor: '#eceff1',
    },
    '& tbody tr td': {
      verticalAlign: 'top',
    },
  },
  value: {},
  input: {
    marginTop: theme.spacing(1),
    maxHeight: 100,
    transition: 'all 500ms',
    overflow: 'hidden',
    '&.hidden': {
      transition: 'all 500ms',
      maxHeight: 0,
    },
  },
  card: {
    margin: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    padding: theme.spacing(2),
    display: 'flex',
    '& .subSection': {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
  },
  cardItem: {
    display: 'block',
    borderLeft: `5px solid ${theme.palette.primary.dark}`,
    borderRadius: 5,
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(2),
    '&.light': { borderColor: theme.palette.primary.main },
    '& .label': { fontWeight: 'bold' },
    '&:last-child': {
      marginBottom: 0,
    },
  },
  modalWrapper: {
    display: 'flex',
    flex: 1,
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(1),
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(1),
  },
  editingRow: {
    display: 'flex',
  },
  mechanic: {
    display: 'flex',
    alignItems: 'center',
  },
}));
