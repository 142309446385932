import { Typography } from '@material-ui/core';
import React from 'react';

const NotFoundServices = () => {
  return (
    <Typography variant='h6'>
      Nenhum serviço encontrado para esta base
    </Typography>
  );
};

export default NotFoundServices;
