import {
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  makeStyles,
  TextField,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

type LoginFormInputsProps = {
  username: string;
  password: string;
  showPassword: boolean;
  setUsername: (username: string) => void;
  setPassword: (password: string) => void;
  setShowPassword: (showPassword: boolean) => void;
};

const useStyles = makeStyles(() => ({
  inputWrapper: {
    backgroundColor: "#fff",
  },
  passwordInputWrapper: {
    padding: "4px 10px",
  },
}));

const LoginFormInputs = ({
  username,
  password,
  showPassword,
  setUsername,
  setPassword,
  setShowPassword,
}: LoginFormInputsProps) => {
  const classes = useStyles();
  return (
    <>
      <Grid item>
        <TextField
          id="stardand-basic"
          label="Login"
          value={username}
          onChange={(event) => setUsername(event.target.value)}
          fullWidth
          variant="filled"
          className={classes.inputWrapper}
        />
      </Grid>
      <Grid item>
        <FormControl
          fullWidth
          variant="filled"
          className={classes.inputWrapper}
        >
          <InputLabel htmlFor="standard-adornment-password">
            Password
          </InputLabel>
          <Input
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            className={classes.passwordInputWrapper}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="Trocar visibilidade da senha"
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={(event) => event.preventDefault()}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </Grid>
    </>
  );
};

export default LoginFormInputs;
