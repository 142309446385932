import {
  Paper,
  Typography,
  TextField,
  MenuItem,
  IconButton,
  Modal,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormHelperText,
} from '@material-ui/core';
import {
  ChangeEventHandler,
  FC,
  MouseEventHandler,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Add as AddIcon } from '@material-ui/icons';
import useFetch from '../../../hooks/useFetch';
import { Product, ServiceProps } from '../../../pages/Mosa/index.d';
import { getProcesses } from '../../../services/api';
import { useStyles } from '../styles';
import useLogin from '../../../hooks/useLogin';

type ProcessType = {
  _id: string;
  type: string;
  code: string;
  description: string;
};

// const activity_types = ['Manutenção', 'Apoio']; // Desativar Apoio enquanto está em desenvolvimento
const activity_types = ['Manutenção'];
const product_types = ['Aeronave', 'Motor', 'Componente', 'Não Aplicável'];

const Process: FC<ServiceProps> = ({
  data,
  setData = () => {},
  onChange,
  errors,
}) => {
  const classes = useStyles();
  const [activityType, setActivityType] = useState<string>('');
  const [modalItemIndex, setModalItemIndex] = useState<number | null>(null);
  const {
    data: { data: processes },
    apiRequest,
  } = useFetch();
  const { token } = useLogin();

  useEffect(() => {
    apiRequest(getProcesses({ token }));
  }, [apiRequest, token]);

  const handleActivityType: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target;
    setActivityType(value);
    setData({ process: undefined, product_type: undefined });
  };

  const isProcessType = useCallback(
    (toTest: string) => {
      if (!processes || !Array.isArray(processes)) return false;
      const selectedProcess = processes.find(
        ({ _id }: ProcessType) => _id === data?.process
      );
      if (!selectedProcess) return false;
      return selectedProcess.type === toTest;
    },
    [data?.process, processes]
  );

  const handleProduct: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target;
    const products = data.products ? [...data.products] : [];
    const hasAircraft = () =>
      products.findIndex(
        (p) =>
          p.pn === data?.aircraft?._model?.model ||
          p.ns === data?.aircraft?.prefix
      ) > -1;

    if (value === 'Aeronave' && data?.aircraft && !hasAircraft()) {
      products.push({
        pn: data?.aircraft?._model?.model,
        ns: data?.aircraft?.prefix,
      });
    }

    setData({ product_type: value, products });
  };

  const handleAddProduct = () => {
    setModalItemIndex(data?.products?.length || 0);
    setData({
      products: [
        ...(data?.products || []),
        { ns: '', pn: '', description: '' },
      ],
    });
  };

  const handleProductEdit = (index: number) => {
    const handler: MouseEventHandler<HTMLDivElement> = () => {
      setModalItemIndex(index);
    };
    return handler;
  };

  const handleFinishEdit = (productData: Product) => {
    if (modalItemIndex === null) return;
    const productListCopy = data.products ? [...data.products] : [];
    productListCopy[modalItemIndex] = productData;
    setData({ ...data, products: productListCopy });
    setModalItemIndex(null);
  };

  const handleDelete = () => {
    if (modalItemIndex === null) return;
    const productListCopy = data.products ? [...data.products] : [];
    productListCopy.splice(modalItemIndex, 1);
    setData({ ...data, products: productListCopy });
    setModalItemIndex(null);
  };

  const handleCloseModal = () => {
    setModalItemIndex(null);
  };

  return (
    <>
      <Paper className={classes.root}>
        <Typography variant='h6' className={classes.title}>
          III – Processo Observado
        </Typography>
        <TextField
          className={classes.input}
          value={activityType}
          onChange={handleActivityType}
          name='position_type'
          fullWidth
          label='Posição'
          select
          required
        >
          {activity_types.map((activity_type) => (
            <MenuItem value={activity_type} key={activity_type}>
              {activity_type}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          className={`${classes.input} ${activityType ? '' : 'hidden'}`}
          value={data?.process || null}
          name='process'
          label='Atividade'
          select
          fullWidth
          onChange={onChange}
        >
          {processes &&
            processes
              .filter(({ type }: ProcessType) => type === activityType)
              .map((item: ProcessType) => (
                <MenuItem key={item._id} value={item._id}>
                  {item.code} - {item.description}
                </MenuItem>
              ))}
        </TextField>
        <TextField
          className={`${classes.input} ${
            isProcessType('Manutenção') ? '' : 'hidden'
          }`}
          value={data?.product_type || null}
          name='product_type'
          label='Produto'
          select
          fullWidth
          onChange={handleProduct}
        >
          {product_types.map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </TextField>

        <Typography variant='h6' className={classes.title}>
          Produtos/Atividades Auditados{' '}
          <IconButton
            color='primary'
            disabled={Boolean(
              isProcessType('Apoio') || data?.product_type === 'Não Aplicável'
            )}
            onClick={handleAddProduct}
          >
            <AddIcon />
          </IconButton>
        </Typography>
        {!data?.products || data.products.length === 0 ? (
          <Typography variant='body1' className={classes.title}>
            Nenhum produto ou atividade inserido
          </Typography>
        ) : (
          data.products.map((product, index) => (
            <ProductCard
              key={index}
              product={product}
              onClick={handleProductEdit(index)}
            />
          ))
        )}
        {errors.includes('products') && (
          <FormHelperText
            error={errors.includes('products')}
            style={{ textAlign: 'center' }}
          >
            Por favor, informe os produtos/atividades auditados
          </FormHelperText>
        )}
      </Paper>
      <ProductModal
        product={
          modalItemIndex !== null && data.products
            ? data.products[modalItemIndex]
            : undefined
        }
        open={Boolean(modalItemIndex !== null && data.products)}
        onClose={handleCloseModal}
        onFinish={handleFinishEdit}
        onDelete={handleDelete}
      />
    </>
  );
};
const ProductCard: FC<{
  product: Product;
  onClick: MouseEventHandler<HTMLDivElement>;
}> = ({ product, onClick }) => {
  const classes = useStyles();
  return (
    <Paper className={classes.card} onClick={onClick}>
      <div>
        <span className={classes.cardItem}>
          <span className='label'>PN ou Modelo: </span>
          {product?.pn}
        </span>
        <span className={classes.cardItem}>
          <span className='label'>Matrícula/NS: </span>
          {product?.ns}
        </span>
        <span className={classes.cardItem}>
          <span className='label'>Descrição: </span>
          {product?.description}
        </span>
      </div>
    </Paper>
  );
};

type ProductModalProps = {
  open: boolean;
  product?: Product;
  onFinish: (data: Product) => void;
  onDelete: () => void;
  onClose: () => void;
};

const ProductModal: FC<ProductModalProps> = ({
  product,
  onFinish,
  open,
  onClose,
  onDelete,
}) => {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [data, setData] = useState<Product>({
    ns: '',
    pn: '',
    description: '',
  });

  useEffect(() => {
    if (product) setData(product);
  }, [product]);

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleConfirmExclusion = () => {
    onDelete();
    handleCloseDialog();
  };

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { name, value } = e.target;
    setData((currentData) => ({ ...currentData, [name]: value }));
  };

  const handleSave = () => {
    onFinish(data);
  };

  return (
    <>
      <ConfirmationDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        onConfirm={handleConfirmExclusion}
      />
      <Modal open={open} onClose={onClose}>
        <div className={classes.modalWrapper}>
          <Paper className={classes.root}>
            <Typography variant='h6' className={classes.title}>
              Adicionar Produto
            </Typography>
            <TextField
              fullWidth
              value={data?.pn || ''}
              name='pn'
              onChange={handleChange}
              label='PN ou Modelo'
            />
            <TextField
              fullWidth
              value={data?.ns || ''}
              name='ns'
              onChange={handleChange}
              label='Matrícula/NS'
            />
            <TextField
              fullWidth
              value={data?.description || ''}
              name='description'
              onChange={handleChange}
              label='Descrição'
              multiline
            />
            <div className={classes.buttonGroup}>
              <Button color='secondary' onClick={handleOpenDialog}>
                Excluir
              </Button>
              <span>
                <Button color='primary' onClick={handleSave}>
                  Confirmar
                </Button>
                <Button color='default' onClick={onClose}>
                  Cancelar
                </Button>
              </span>
            </div>
          </Paper>
        </div>
      </Modal>
    </>
  );
};

const ConfirmationDialog: FC<{
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}> = ({ open, onClose, onConfirm }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Tem certeza?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Você realmente deseja remover este produto da lista?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onConfirm} color='primary' autoFocus>
          Confirmar
        </Button>
        <Button color='secondary' onClick={onClose}>
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Process;
