import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  makeStyles,
  Snackbar,
} from '@material-ui/core';
import { ChangeEventHandler, FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import Header from '../../components/Header';
import {
  Identification,
  Process,
  Summary,
  References,
  Events,
  Comments,
  PresenceList,
} from '../../components/MOSA';
import {
  Identification as IdentificationReview,
  Process as ProcessReview,
  Summary as SummaryReview,
  References as ReferencesReview,
  Events as EventsReview,
  Comments as CommentsReview,
  PresenceList as PresenceListReview,
} from '../../components/MOSAReview';
import useFetch from '../../hooks/useFetch';
import { getService, sendEmail, sendMOSA } from '../../services/api';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { Service } from './index.d';
import { Alert } from '@material-ui/lab';
import useLogin from '../../hooks/useLogin';

const useStyles = makeStyles((theme) => ({
  bg: {
    backgroundColor: theme.palette.primary.dark,
    height: '100vh',
  },
  root: {
    backgroundColor: theme.palette.primary.dark,
    minHeight: '100vh',
    padding: theme.spacing(1),
    '& .MuiPaper-root': {
      marginBottom: theme.spacing(1),
    },
  },
  dialogPaper: {
    backgroundColor: theme.palette.grey[200],
  },
}));

const sections = [
  Summary,
  Identification,
  Process,
  References,
  Events,
  Comments,
  PresenceList,
];

const Mosa = () => {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [errors, setErrors] = useState<Array<string>>([]);
  const [toastMessage, setToastMessage] = useState({
    opened: false,
    message: '',
  });
  const { id } = useParams<{ id: string }>();
  const { token } = useLogin();
  const {
    data: { data },
    loading,
    setData,
    apiRequest,
  } = useFetch();

  useEffect(() => {
    if (!id || !token || !apiRequest) return;
    apiRequest(getService({ id, token }));
  }, [id, token, apiRequest]);

  function handleChangeDate(field: string) {
    return function (date: MaterialUiPickersDate) {
      setData((currentData: any) => ({
        ...currentData,
        data: { ...currentData.data, [field]: date?.toISOString() },
      }));
    };
  }

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { name, value } = e.target;
    setData((currentData: any) => ({
      ...currentData,
      data: { ...currentData.data, [name]: value },
    }));
  };

  const customSetData = (data: {}) => {
    setData((currentData: any) => ({
      ...currentData,
      data: { ...currentData.data, ...data },
    }));
  };
  function handleCloseAlert() {
    setToastMessage({
      opened: false,
      message: toastMessage.message,
    });
  }

  const handleSend = () => {
    const errorList = [];
    if (!data.mosa_start) errorList.push('mosa_start');
    if (!data.mosa_end) errorList.push('mosa_end');
    if (
      (!data.products || data.products.length === 0) &&
      data.product_type &&
      data.product_type !== 'Não Aplicável'
    )
      errorList.push('products');
    if (!data.references || data.references.length === 0)
      errorList.push('references');
    if (!data.presence_list || data.presence_list.length === 0)
      errorList.push('presence_list');
    if (errorList.length > 0) return setErrors(errorList);
    const dateAndTimeEnd = new Date(data.mosa_end);
    const startDate = new Date(data.mosa_start);
    dateAndTimeEnd.setFullYear(startDate.getFullYear());
    dateAndTimeEnd.setMonth(startDate.getMonth());
    dateAndTimeEnd.setDate(startDate.getDate());
    const code = moment(dateAndTimeEnd).format('YMMDDHHmm');
    customSetData({ code });
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleError = (errorMessage: string) => {
    setToastMessage({
      opened: true,
      message: errorMessage,
    });
  };

  return (
    <div className={classes.bg}>
      <Header showGoBackIcon />
      {loading ? (
        <LinearProgress />
      ) : (
        <main className={classes.root}>
          {sections.map((Component, index) => (
            <Component
              data={data}
              onChangeDate={handleChangeDate}
              onChange={handleChange}
              key={index}
              setData={customSetData}
              errors={errors}
            />
          ))}
          <Button
            variant='contained'
            fullWidth
            color='primary'
            onClick={handleSend}
          >
            Enviar Relatório
          </Button>
        </main>
      )}
      <ConfirmReportDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        data={data}
        onError={handleError}
      />
      <Snackbar
        open={toastMessage.opened}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity='error' onClose={handleCloseAlert}>
          {toastMessage.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

type ConfirmReportDialogProps = {
  open: boolean;
  onClose: () => void;
  data: Service;
  onError: (message: string) => void;
};

const reviewSections = [
  SummaryReview,
  IdentificationReview,
  ProcessReview,
  ReferencesReview,
  EventsReview,
  CommentsReview,
  PresenceListReview,
];

function fixTimezone(date: string | Date, invert: boolean = false) {
  const dateObj = new Date(date);
  dateObj.setMinutes(
    invert
      ? dateObj.getMinutes() + dateObj.getTimezoneOffset()
      : dateObj.getMinutes() - dateObj.getTimezoneOffset()
  );
  return dateObj;
}

const ConfirmReportDialog: FC<ConfirmReportDialogProps> = ({
  open,
  onClose,
  data,
  onError,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const {
    user: { id },
    token,
  } = useLogin();

  const handleSend = () => {
    const now = fixTimezone(new Date());
    const inspector = {
      inspector: id,
      confirmed: true,
      confirmation_date: now,
    };
    const mosa_status = 'MOSA Realizada';
    const mosa_sent = now;
    const mosa_start = data.mosa_start
      ? fixTimezone(data.mosa_start)
      : data.mosa_start;
    const mosa_end = data.mosa_end ? fixTimezone(data.mosa_end) : data.mosa_end;

    sendMOSA({
      data: {
        ...data,
        inspector,
        mosa_status,
        mosa_sent,
        mosa_start,
        mosa_end,
      },
      token,
    }).then((MOSAResponse: Response) => {
      if (MOSAResponse.ok) {
        sendEmail({ id: data._id, token }).then((emailResponse: Response) => {
          if (emailResponse.ok) {
            history.goBack();
          } else {
            onError(
              'Erro - não foi possivel enviar os e-mails de confirmação.'
            );
          }
        });
      } else {
        onError('Erro - não foi possivel enviar relatório MOSA.');
      }
    });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ className: classes.dialogPaper }}
    >
      <DialogTitle>Revisar Relatório</DialogTitle>
      <DialogContent>
        {reviewSections.map((Component, index) => (
          <Component key={index} data={data} />
        ))}
      </DialogContent>
      <DialogActions>
        <Button color='primary' onClick={handleSend}>
          Confirmar
        </Button>
        <Button color='secondary' onClick={onClose}>
          Voltar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Mosa;
