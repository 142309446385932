import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';

type ConfirmationModalProps = {
  open: boolean;
  message: string;
  onClose: () => void;
  onConfirm: () => void;
};

const ConfirmationModal = ({
  open,
  message,
  onClose,
  onConfirm,
}: ConfirmationModalProps) => {
  return (
    <Dialog open={open} fullWidth maxWidth='sm' onClose={onClose}>
      <DialogTitle>Confirmação</DialogTitle>
      <DialogContent>
        <Typography gutterBottom>{message}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='secondary' variant='contained'>
          Cancelar
        </Button>
        <Button onClick={onConfirm} color='primary' variant='contained'>
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
