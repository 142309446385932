import {
  Paper,
  Typography,
  Button,
  TextField,
  CircularProgress,
  IconButton,
  FormHelperText,
} from '@material-ui/core';
import {
  Add as AddIcon,
  Check as ConfirmIcon,
  Close as CancelIcon,
  KeyboardArrowRight as TickIcon,
} from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { FC, useEffect, useState } from 'react';
import { ServiceProps, User } from '../../../pages/Mosa/index.d';
import { useStyles } from '../styles';
import useFetch from '../../../hooks/useFetch';
import { getUsers } from '../../../services/api';
import useLogin from '../../../hooks/useLogin';

const PresenceList: FC<ServiceProps> = ({
  data,
  setData = () => {},
  errors,
}) => {
  const classes = useStyles();
  const [editing, setEditing] = useState<number | null>(null);
  const { data: users, loading, apiRequest } = useFetch();
  const {
    user: { id: userId },
    token,
  } = useLogin();

  useEffect(() => {
    if (!apiRequest) return;
    apiRequest(getUsers({ token }));
  }, [apiRequest, token]);

  const handleAddMechanic = () => {
    setEditing(data?.presence_list?.length || 0);
    setData({
      ...data,
      presence_list: [
        ...data.presence_list,
        { inspector: userId, mechanic: null },
      ],
    });
  };
  const handleDeleteMechanic = () => {
    if (editing === null) return;
    const presenceListCopy = [...data.presence_list];
    presenceListCopy.splice(editing, 1);
    setData({ ...data, presence_list: presenceListCopy });
    setEditing(null);
  };

  const handleChange = (e: any, value: any) => {
    if (editing === null) return;
    const presenceListCopy = [...data.presence_list];
    presenceListCopy[editing] = {
      ...presenceListCopy[editing],
      mechanic: value,
    };
    setData({ ...data, presence_list: presenceListCopy });
  };

  const MechanicInput = () => {
    if (editing === null) return null;
    return (
      <div className={classes.editingRow}>
        <Autocomplete
          fullWidth
          options={users.data ? (users.data as Array<User>) : []}
          value={data.presence_list[editing]?.mechanic || null}
          getOptionSelected={(option) =>
            option._id === data.presence_list[editing]?.mechanic?._id
          }
          getOptionLabel={(option) =>
            `${option.name} - ${option.registration || 'Sem registro'}`
          }
          onChange={handleChange}
          loading={loading}
          autoComplete
          renderInput={(params) => (
            <TextField
              {...params}
              label='Colaborador'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color='inherit' size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
        <IconButton
          color='primary'
          onClick={() => {
            if (!data.presence_list[editing].mechanic) handleDeleteMechanic();
            setEditing(null);
          }}
        >
          <ConfirmIcon />
        </IconButton>
        <IconButton color='secondary' onClick={handleDeleteMechanic}>
          <CancelIcon />
        </IconButton>
      </div>
    );
  };

  return (
    <Paper className={classes.root}>
      <Typography variant='h6' className={classes.title}>
        IX - Lista de Presença
      </Typography>
      {data?.presence_list?.length === 0 ? (
        <Typography variant='body1' className={classes.title}>
          Nenhum colaborador inserido
        </Typography>
      ) : (
        data?.presence_list.map((item, index) =>
          editing === index ? (
            <MechanicInput key={index} />
          ) : (
            <Typography
              key={index}
              variant='body1'
              className={classes.mechanic}
              onClick={() => {
                if (editing === null) setEditing(index);
              }}
            >
              <TickIcon />
              {item.mechanic.name} -{' '}
              {item.mechanic.registration || 'Sem registro'}
            </Typography>
          )
        )
      )}
      {errors.includes('presence_list') && (
        <FormHelperText
          error={errors.includes('presence_list')}
          style={{ textAlign: 'center' }}
        >
          Por favor, indique os colaboradores participantes
        </FormHelperText>
      )}
      <Button
        color='primary'
        disabled={editing !== null}
        startIcon={<AddIcon />}
        onClick={handleAddMechanic}
      >
        Adicionar Colaborador
      </Button>
    </Paper>
  );
};

export default PresenceList;
