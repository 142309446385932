import { Container, Grid, Typography, makeStyles } from '@material-ui/core';
import LoginForm from '../../components/LoginForm';

import backgroundImage from '../../assets/images/background-login.jpg';
import logoImage from '../../assets/images/logo.png';
import { useHistory } from 'react-router-dom';
import useLogin from '../../hooks/useLogin';

const useStyles = makeStyles(() => ({
  root: {
    minHeight: '100vh',
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: '#fff',
    padding: '0 16px',
  },
  presentationWrapper: {
    marginBottom: 32,
  },
  logoWrapper: {
    marginTop: 32,
    marginBottom: 16,
  },
  loginFormWrapper: {
    marginBottom: 64,
  },
}));

const Login = () => {
  const classes = useStyles();
  const history = useHistory();
  const { isLogged } = useLogin();

  if (isLogged) {
    history.push('/');
    return <></>;
  }

  return (
    <div className={classes.root}>
      <Container maxWidth='sm'>
        <Grid
          container
          direction='column'
          alignItems='center'
          className={classes.presentationWrapper}
        >
          <div className={classes.logoWrapper}>
            <img src={logoImage} alt='Logotipo da OMNI' />
          </div>
          <Typography variant='h6'>Fast Track</Typography>
        </Grid>
        <div className={classes.loginFormWrapper}>
          <LoginForm />
        </div>
        <Grid container direction='column' alignItems='center'>
          <Typography>Powered by Criatech</Typography>
        </Grid>
      </Container>
    </div>
  );
};

export default Login;
