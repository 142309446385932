import {
  Container,
  Grid,
  Typography,
  makeStyles,
  TextField,
  Button,
} from '@material-ui/core';

import backgroundImage from '../../assets/images/background-login.jpg';
import { FormEvent, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { recoverPassword } from '../../services/api';
import { toast } from 'react-toastify';
import useLogin from '../../hooks/useLogin';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '100vh',
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: '#fff',
    padding: '0 16px',
  },
  inputWrapper: {
    backgroundColor: '#fff',
    marginTop: 16,
    marginBottom: 8,
  },
}));

const RecoverPassword = () => {
  const [email, setEmail] = useState('');
  const classes = useStyles();
  const history = useHistory();
  const { isLogged } = useLogin();

  if (isLogged) {
    history.push('/');
    return <></>;
  }

  async function handleSubmit(event: FormEvent) {
    event.preventDefault();
    const response = await recoverPassword(email);
    if (response.ok) {
      toast.success(response.message);
    } else {
      toast.error(
        'Ocorreu um erro ao tentar recuperar sua senha. Verifique seu e-mail e tente novamente!'
      );
    }
  }

  return (
    <div className={classes.root}>
      <Container maxWidth='sm'>
        <Grid container direction='column' alignItems='center'>
          <Typography variant='subtitle2'>
            Informe seu e-mail para solicitar a recuperação de senha
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              id='stardand-basic'
              label='E-mail'
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              fullWidth
              variant='filled'
              className={classes.inputWrapper}
              type='email'
            />
            <Button
              variant='contained'
              color='primary'
              fullWidth
              type='submit'
              size='large'
            >
              Solicitar
            </Button>
          </form>
          <Link
            to='/login'
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '16px',
              textDecoration: 'none',
            }}
          >
            <Typography style={{ color: '#FFF' }}>Voltar ao login</Typography>
          </Link>
        </Grid>
      </Container>
    </div>
  );
};

export default RecoverPassword;
