import { useState } from 'react';
import { Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import EventIcon from '@material-ui/icons/Event';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import RequestMosaModal from '../RequestMosaModal';
import ManageMosaModal from '../ManageMosaModal';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  cardWrapper: {
    transition: theme.transitions.create('filter'),
    marginTop: theme.spacing(2),
    cursor: 'pointer',
    '&:hover, &:focus': {
      filter: 'brightness(90%)',
    },
  },
  paperHeader: {
    width: '100%',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  positionInfoWrapper: {
    minHeight: '60px',
    padding: theme.spacing(2),
    background: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    gap: theme.spacing(2),
  },
  footerWrapper: {
    display: 'grid',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    gridTemplateColumns: 'repeat(3, 1fr)',
    padding: theme.spacing(2),
  },
  footerItemWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  footerTypography: {
    marginTop: theme.spacing(1),
    textAlign: 'center',
  },
}));

export type ServiceCardProps = {
  _id?: string;
  code?: string;
  aircraft?: {
    prefix?: string;
    _model: {
      model?: string;
    };
  };
  base?: {
    _id?: string;
    id?: string;
    code?: string;
    name?: string;
  };
  hangar?: {
    _id?: string;
    id?: string;
    code?: string;
  };
  inspection?: string;
  inspector?: {
    inspector?: string;
    confirmed?: boolean;
    confirmation_date?: Date;
  };
  position?: number;
  status?: string;
  mosa_status?: string;
  mosa_sent?: Date;
  reason?: string;
  reason_obs?: string;
  isMosa?: boolean;
  execute_prediction?: string;
  start_service?: string;
  end_service?: string;
};

const ServiceCard = (serviceParam: ServiceCardProps) => {
  const classes = useStyles();
  const [service, setService] = useState<ServiceCardProps | null>(serviceParam);
  const [modalOpened, setModalOpened] = useState(false);

  function handleOpenModal() {
    if (service) setModalOpened((oldModalOpened) => !oldModalOpened);
  }

  return (
    <>
      {service && (
        <Paper
          className={classes.cardWrapper}
          elevation={2}
          onClick={handleOpenModal}
        >
          <Grid
            container
            justifyContent='space-between'
            align-items='center'
            className={classes.paperHeader}
          >
            <b>{service.aircraft?.prefix || '-'}</b>
            <FlightTakeoffIcon />
            <span>{service.aircraft?._model?.model || '-'}</span>
          </Grid>
          <Grid
            container
            direction='column'
            alignItems='center'
            justifyContent='space-between'
            className={classes.positionInfoWrapper}
          >
            <Typography variant='body2'>{service.inspection}</Typography>
            <Typography variant='body1'>
              {service.hangar?.code && `${service.hangar.code} -`} Posição{' '}
              {service.position || 'não definida'}
            </Typography>
          </Grid>
          <div className={classes.footerWrapper}>
            <div className={classes.footerItemWrapper}>
              <EventIcon />
              <Typography variant='body2' className={classes.footerTypography}>
                {moment(service.execute_prediction).format('DD/MM/YYYY')}
              </Typography>
            </div>
            <div className={classes.footerItemWrapper}>
              <DoneAllIcon />
              <Typography variant='body2' className={classes.footerTypography}>
                {service.mosa_status || '-'}
              </Typography>
            </div>
            <div className={classes.footerItemWrapper}>
              <QueryBuilderIcon />
              <Typography variant='body2' className={classes.footerTypography}>
                {moment(service.execute_prediction).format('HH:mm')}
              </Typography>
            </div>
          </div>
        </Paper>
      )}
      {service && !service.isMosa && (
        <RequestMosaModal
          service={service}
          setService={setService}
          open={modalOpened}
          setOpen={setModalOpened}
        />
      )}
      {service && service.isMosa && (
        <ManageMosaModal
          service={service}
          setService={setService}
          open={modalOpened}
          setOpen={setModalOpened}
        />
      )}
    </>
  );
};

export default ServiceCard;
