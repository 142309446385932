import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { API_URL } from '../../services/api';
import { AppDispatch } from '../configureStore';

export type NotificationProps = { type: string; value: string };

type NotificationsSliceProps = {
  loading: boolean;
  data: NotificationProps[] | null;
  error: string | null;
};

const initialState: NotificationsSliceProps = {
  loading: false,
  data: null,
  error: null,
};

const notificationsSlicer = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    fetchStarted(state) {
      state.loading = true;
    },
    fetchSuccess(state, action: PayloadAction<NotificationProps[]>) {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    },
    fetchError(state, action: PayloadAction<string>) {
      state.loading = false;
      state.data = null;
      state.error = action.payload;
    },
  },
});

export const { fetchStarted, fetchSuccess, fetchError } =
  notificationsSlicer.actions;

export const fetchNotifications =
  (token: string, userId: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(fetchStarted());
      const header = new Headers();
      header.append('Content-Type', 'application/json');
      header.append('Authorization', `Bearer ${token}`);
      const response = await fetch(
        API_URL + '/notifications-central/' + userId,
        {
          method: 'GET',
          headers: header,
        }
      );
      const jsonResponse = (await response.json()) as NotificationProps[];
      dispatch(fetchSuccess(jsonResponse));
    } catch (error) {
      return dispatch(fetchError(error.message));
    }
  };

export const notificationsReducer = notificationsSlicer.reducer;
