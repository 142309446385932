import {
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {
  AircraftProps,
  CreateServiceProps,
  HangarProps,
} from '../../services/api';
import { BaseProps } from '../../store/slices/bases';

const useStyles = makeStyles((theme) => ({
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  formTitle: {
    textTransform: 'uppercase',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  formSectionWrapper: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  formControlWrapper: {
    marginTop: theme.spacing(2),
  },
  switchWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  isMosaSwitchWrapper: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
  },
  submitButton: {
    width: '100%',
    marginBottom: theme.spacing(4),
  },
}));

type CreateServiceFormProps = {
  form: CreateServiceProps;
  setForm: (argument: CreateServiceProps) => void;
  hangars: HangarProps[];
  hangarsLoading: boolean;
  bases: BaseProps[];
  basesLoading: boolean;
  airCrafts: AircraftProps[];
  airCraftsLoading: boolean;
  apiLoading: boolean;
};

const CreateServiceForm = ({
  form,
  setForm,
  hangars,
  hangarsLoading,
  bases,
  basesLoading,
  airCrafts,
  airCraftsLoading,
  apiLoading,
}: CreateServiceFormProps) => {
  const classes = useStyles();
  return (
    <div>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <FormGroup row className={classes.formWrapper}>
          <Grid
            container
            className={classes.formSectionWrapper}
            direction='column'
          >
            <Typography
              variant='subtitle1'
              align='center'
              className={classes.formTitle}
              color='secondary'
            >
              Dados do serviço
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  checked={form.active}
                  onChange={() => setForm({ ...form, active: !form.active })}
                  name='ativo'
                />
              }
              label='ATIVO'
              labelPlacement='start'
              className={classes.switchWrapper}
            />
            <FormControl className={classes.formControlWrapper}>
              <InputLabel id='aeronave'>Aeronave</InputLabel>
              <Select
                labelId='aeronave'
                onChange={(event) =>
                  setForm({ ...form, aircraft: event.target.value as string })
                }
                value={form.aircraft || ''}
                disabled={airCraftsLoading}
                required
              >
                <MenuItem value=''>Selecione uma aeronave</MenuItem>
                {airCrafts.map((aircraft) => (
                  <MenuItem value={aircraft._id}>
                    {aircraft.prefix} ({aircraft._model.manufacturer} -{' '}
                    {aircraft._model.model})
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={classes.formControlWrapper}>
              <InputLabel id='bases'>Bases</InputLabel>
              <Select
                labelId='bases'
                onChange={(event) =>
                  setForm({ ...form, base: event.target.value as string })
                }
                value={form.base || ''}
                disabled={basesLoading}
                required
              >
                <MenuItem value=''>Selecione uma base</MenuItem>
                {bases.map((base) => (
                  <MenuItem value={base._id}>
                    {base.name} ({base.code})
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={classes.formControlWrapper}>
              <TextField
                label='Inspeção'
                required={true}
                value={form.inspection}
                onChange={(event) =>
                  setForm({
                    ...form,
                    inspection: event.target.value as string,
                  })
                }
              />
            </FormControl>
            <FormControl className={classes.formControlWrapper}>
              <DateTimePicker
                margin='normal'
                label='Previsão de execução'
                format='dd/MM/yyyy HH:mm'
                value={
                  !!form.execute_prediction ? form.execute_prediction : null
                }
                variant='dialog'
                ampm={false}
                onChange={(date) =>
                  setForm({
                    ...form,
                    execute_prediction: date || undefined,
                  })
                }
                required
              />
            </FormControl>
            <FormControl className={classes.formControlWrapper}>
              <FormLabel component='legend'>Tipo de registro</FormLabel>
              <RadioGroup
                aria-label='tipo de registro'
                name='gender1'
                value={form.register_type}
                onChange={(event) =>
                  setForm({ ...form, register_type: event.target.value })
                }
              >
                <FormControlLabel value='TC' control={<Radio />} label='TC' />
                <FormControlLabel value='DB' control={<Radio />} label='DB' />
              </RadioGroup>
            </FormControl>
            <FormControl className={classes.formControlWrapper}>
              <TextField
                label='Registro'
                value={form.register}
                onChange={(event) =>
                  setForm({ ...form, register: event.target.value as string })
                }
              />
            </FormControl>
            <Typography
              variant='subtitle1'
              align='center'
              className={classes.formTitle}
              color='secondary'
            >
              Posicionamento da aeronave no hangar
            </Typography>
            <FormControl className={classes.formControlWrapper}>
              <InputLabel id='posicionamento'>
                Posicionamento da aeronave
              </InputLabel>
              <Select
                labelId='posicionamento'
                onChange={(event) =>
                  setForm({
                    ...form,
                    hangarPosition: {
                      ...form.hangarPosition,
                      position: Number(event.target.value) || undefined,
                    },
                    position: Number(event.target.value) || undefined,
                  })
                }
                value={form.hangarPosition?.position || ''}
                disabled={hangarsLoading}
                required
              >
                <MenuItem value=''>Selecione um posicionamento</MenuItem>
                {hangars[0] &&
                  hangars[0].positions &&
                  hangars[0].positions.map((hangar, index) => (
                    <MenuItem value={index + 1}>
                      {hangars[0].code} - Posição {index + 1}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl className={classes.formControlWrapper}>
              <TextField
                label='Homem/hora'
                value={form.man_hour_value}
                onChange={(event) =>
                  setForm({
                    ...form,
                    man_hour_value: event.target.value || '',
                  })
                }
                type='number'
              />
            </FormControl>
            <FormControl className={classes.formControlWrapper}>
              <DateTimePicker
                margin='normal'
                label='Data de ínicio'
                format='dd/MM/yyyy HH:mm'
                value={!!form.start_service ? form.start_service : null}
                variant='dialog'
                ampm={false}
                onChange={(date) =>
                  setForm({
                    ...form,
                    start_service: date || undefined,
                  })
                }
              />
            </FormControl>
            <FormControl className={classes.formControlWrapper}>
              <DateTimePicker
                margin='normal'
                label='Data de término'
                format='dd/MM/yyyy HH:mm'
                value={!!form.end_service ? form.end_service : null}
                variant='dialog'
                ampm={false}
                onChange={(date) =>
                  setForm({
                    ...form,
                    end_service: date || undefined,
                  })
                }
              />
            </FormControl>
            <FormControl className={classes.formControlWrapper}>
              <TextField
                label='Créditos em horas'
                value={form.hour_credits}
                onChange={(event) =>
                  setForm({
                    ...form,
                    hour_credits: event.target.value || '',
                  })
                }
                type='number'
              />
            </FormControl>
            <FormControl className={classes.formControlWrapper}>
              <TextField
                label='Créditos em dias'
                value={form.day_credits}
                onChange={(event) =>
                  setForm({
                    ...form,
                    day_credits: event.target.value || '',
                  })
                }
                type='number'
              />
            </FormControl>
            <FormControl className={classes.formControlWrapper}>
              <TextField
                label='Créditos em ciclos'
                value={form.circle_credits}
                onChange={(event) =>
                  setForm({
                    ...form,
                    circle_credits: event.target.value || '',
                  })
                }
                type='number'
              />
            </FormControl>
            <FormControl className={classes.formControlWrapper}>
              <TextField
                label='Observações'
                value={form.observation}
                onChange={(event) =>
                  setForm({
                    ...form,
                    observation: event.target.value as string,
                  })
                }
                multiline
              />
            </FormControl>
            <FormControlLabel
              control={
                <Switch
                  checked={form.isMosa}
                  onChange={() => setForm({ ...form, isMosa: !form.isMosa })}
                  name='mosa'
                />
              }
              label='MOSA'
              labelPlacement='start'
              className={classes.isMosaSwitchWrapper}
            />
          </Grid>
          <FormControl className={classes.formSectionWrapper}>
            <Button
              variant='contained'
              color='primary'
              type='submit'
              className={classes.submitButton}
              disabled={apiLoading}
            >
              Salvar
            </Button>
          </FormControl>
        </FormGroup>
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default CreateServiceForm;
