import { makeStyles, CircularProgress, Fab } from '@material-ui/core';
import ServicesHeader from '../../components/ServicesHeader';
import ServiceCard, { ServiceCardProps } from '../../components/ServiceCard';
import { useHistory, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { findNonMosaServicesById } from '../../services/api';
import useFetch from '../../hooks/useFetch';
import FindReplaceIcon from '@material-ui/icons/FindReplace';
import PlusIcon from '@material-ui/icons/Add';
import NotFoundServices from '../../components/NotFoundServices';
import { FilterProps } from '../../components/FilterModal';
import useLogin from '../../hooks/useLogin';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(20),
    background: theme.palette.primary.light,
    minHeight: '100vh',
  },
  cardWrapper: {
    marginTop: theme.spacing(2),
  },
  paperHeader: {
    width: '100%',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  positionInfoWrapper: {
    minHeight: '60px',
    padding: theme.spacing(2),
    background: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    gap: theme.spacing(2),
  },
  footerWrapper: {
    display: 'grid',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    gridTemplateColumns: 'repeat(3, 1fr)',
    padding: theme.spacing(2),
  },
  footerItemWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  footerTypography: {
    marginTop: theme.spacing(1),
    textAlign: 'center',
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
  },
  fabsWrapper: {
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    bottom: theme.spacing(2),
    right: theme.spacing(1),
  },
  fabWrapper: {
    width: 'fit-content',
    margin: theme.spacing(1),
  },
  fabIcon: {
    marginRight: theme.spacing(1),
  },
}));

const NonMosaServices = () => {
  const [services, setServices] = useState<ServiceCardProps[]>([]);
  const [headerFilter, setHeaderFilter] = useState<FilterProps>();
  const { user, token } = useLogin();
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { data, loading, apiRequest } = useFetch();

  function handleCreateNewService() {
    history.push(`/create-service/${id}`);
  }

  function handleSwitchToMosaServices() {
    history.push(`/services/${id}`);
  }

  useEffect(() => {
    const requestParams = {
      filter: {
        base: id,
        aircraftModel: headerFilter?.aircraft?._model?._id || 'all',
        aircraft: headerFilter?.aircraft?.prefix || '',
      },
      page: 1,
      userId: user.id,
      token,
    };
    if (token) apiRequest(findNonMosaServicesById(requestParams));
  }, [id, user, token, apiRequest, headerFilter]);

  useEffect(() => {
    if (data?.data?.result && user.auditing) {
      setServices(data.data.result);
    }
  }, [data, user.auditing]);

  return (
    <>
      <ServicesHeader setFilter={setHeaderFilter} />
      <main className={classes.root}>
        {loading ? (
          <div className={classes.spinner}>
            <CircularProgress />
          </div>
        ) : (
          <>
            {services && services.length > 0 ? (
              services.map((service) => <ServiceCard {...service} />)
            ) : (
              <NotFoundServices />
            )}
          </>
        )}
        <div className={classes.fabsWrapper}>
          <Fab
            variant='extended'
            size='large'
            color='primary'
            aria-label='criar um novo serviço'
            className={classes.fabWrapper}
            onClick={handleCreateNewService}
          >
            <PlusIcon className={classes.fabIcon} />
            Criar um novo serviço
          </Fab>
          <Fab
            variant='extended'
            size='large'
            color='secondary'
            aria-label='listar serviços mosa'
            className={classes.fabWrapper}
            onClick={handleSwitchToMosaServices}
          >
            <FindReplaceIcon className={classes.fabIcon} />
            Exibir serviços mosa
          </Fab>
        </div>
      </main>
    </>
  );
};

export default NonMosaServices;
