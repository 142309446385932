import { Paper, Typography } from '@material-ui/core';
import { FC } from 'react';
import { ReviewServiceProps } from '../../../pages/Mosa/index.d';
import { useStyles } from '../styles';

const Comments: FC<ReviewServiceProps> = ({ data }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Typography variant="body1" className={classes.sectionTitle}>
        VI – Comentários do Auditor
      </Typography>
      <Typography variant="caption" className={classes.title}>
        Pontos Positivos
      </Typography>
      <Typography variant="body1" className={classes.value}>
        {data?.good_points}
      </Typography>
      <Typography variant="caption" className={classes.title}>
        Pontos Negativos
      </Typography>
      <Typography variant="body1" className={classes.value}>
        {data?.bad_points}
      </Typography>
      <Typography variant="caption" className={classes.title}>
        Comentários Gerais
      </Typography>
      <Typography variant="body1" className={classes.value}>
        {data?.comments}
      </Typography>
    </Paper>
  );
};

export default Comments;
