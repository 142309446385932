import { createTheme } from '@material-ui/core';

const theme = createTheme({
  palette: {
    primary: {
      main: '#2196F3',
      light: '#CFD8DD',
      dark: '#015886',
    },
  },
});

export default theme;
