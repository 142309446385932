import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authUser } from '../services/api';
import { StoreTypes } from '../store/configureStore';
import { clearToken, configureToken } from '../store/slices/token';
import { clearUser, configureUser } from '../store/slices/user';

const useLogin = () => {
  const { token } = useSelector((store: StoreTypes) => store.token);
  const user = useSelector((store: StoreTypes) => store.user);
  const dispatch = useDispatch();

  const isLogged = !!token || !!localStorage.getItem('token');

  const logoutUser = useCallback(() => {
    dispatch(clearUser());
    dispatch(clearToken());
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    window.location.pathname = '/login';
  }, [dispatch]);

  const saveToLocalStorage = useCallback(() => {
    if (token) localStorage.setItem('token', token);
    if (user?.id) localStorage.setItem('user', JSON.stringify(user));
  }, [user, token]);

  const authToken = useCallback(
    async (token: string) => {
      const authResponse = await authUser(token);
      if (authResponse.ok) {
        dispatch(
          configureToken({
            token,
          })
        );
      } else {
        logoutUser();
      }
    },
    [dispatch, logoutUser]
  );

  const loadFromLocalStorage = useCallback(() => {
    const localToken = localStorage.getItem('token');
    const localUser = localStorage.getItem('user');
    if (localToken) {
      authToken(localToken);
    }
    if (localUser) {
      const localUserJSON = JSON.parse(localUser);
      dispatch(
        configureUser({
          ...localUserJSON,
        })
      );
    }
  }, [dispatch, authToken]);

  return {
    isLogged,
    token,
    user,
    saveToLocalStorage,
    loadFromLocalStorage,
    logoutUser,
  };
};

export default useLogin;
