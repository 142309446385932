import {
  AppBar,
  IconButton,
  makeStyles,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import GoBackIcon from '@material-ui/icons/ArrowBack';
import { FormEvent, useCallback, useEffect, useState } from 'react';
import useFetch from '../../hooks/useFetch';
import {
  AircraftProps,
  createService,
  CreateServiceProps,
  findAllAircrafts,
  findAllHangars,
  HangarProps,
} from '../../services/api';
import CreateServiceForm from '../../components/CreateServiceForm';
import { toast } from 'react-toastify';
import useLogin from '../../hooks/useLogin';
import useBases from '../../hooks/useBaseServices';
import { BaseProps } from '../../store/slices/bases';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    justifyContent: 'flex-start',
  },
  iconButton: {
    color: theme.palette.primary.contrastText,
    marginRight: theme.spacing(2),
  },
}));

const CreateService = () => {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const { user, token } = useLogin();
  const [form, setForm] = useState<CreateServiceProps>({
    active: true,
    isMosa: true,
    inspection: '',
    base: id,
    hangarPosition: {
      hangar: {},
    },
    mosa_selected_by: '',
    hangar: '',
    status: 'Conferido',
  });
  const [airCrafts, setAirCrafts] = useState<AircraftProps[]>([]);
  const [bases, setBases] = useState<BaseProps[]>([]);
  const [hangars, setHangars] = useState<HangarProps[]>([]);
  const history = useHistory();
  const {
    apiRequest: airCraftsRequest,
    data: airCraftsData,
    loading: airCraftsLoading,
  } = useFetch();
  const { basesData, basesLoading } = useBases();
  const {
    apiRequest: hangarsRequest,
    data: hangarsData,
    loading: hangarsLoading,
  } = useFetch();
  const {
    apiRequest,
    data: apiData,
    error: apiError,
    setError: setApiError,
    loading: apiLoading,
  } = useFetch();

  const handleGoBack = useCallback(() => {
    history.goBack();
  }, [history]);

  useEffect(() => {
    if (token) {
      airCraftsRequest(findAllAircrafts(token));
    }
  }, [airCraftsRequest, hangarsRequest, token]);

  // Faz a procura dos hangares toda vez que a base é alterada
  useEffect(() => {
    if (token) {
      hangarsRequest(findAllHangars(token, form.base));
    }
  }, [hangarsRequest, token, form.base]);

  // Atualiza os estados logo após o fetch
  useEffect(() => {
    if (airCraftsData?.data) {
      setAirCrafts(airCraftsData.data);
    }
  }, [airCraftsData]);
  useEffect(() => {
    if (basesData) {
      setBases(basesData);
    }
  }, [basesData]);
  useEffect(() => {
    if (hangarsData?.data) {
      setHangars(hangarsData.data);
    }
  }, [hangarsData]);

  // Alterar o valor do hangar ao carregar os dados da api
  useEffect(() => {
    if (hangars[0]) {
      setForm((oldForm) => ({
        ...oldForm,
        hangar: hangars[0]._id || '',
        hangarPosition: { ...oldForm.hangarPosition, hangar: hangars[0] },
      }));
    }
  }, [hangars]);

  // Verificar se o request de criação de serviço foi bem sucedido
  useEffect(() => {
    if (apiData.ok && apiData?.message) {
      handleGoBack();
      toast.success(apiData.message);
    }
  }, [apiData, handleGoBack]);

  useEffect(() => {
    if (user.id && form.isMosa) {
      setForm((oldForm) => ({
        ...oldForm,
        mosa_status: 'MOSA Solicitada pela Manutenção',
        mosa_selected_by: user.id,
      }));
    } else {
      setForm((oldForm) => ({
        ...oldForm,
        mosa_selected_by: user.id,
        mosa_status: undefined,
      }));
    }
  }, [user.id, form.isMosa]);

  function handleSubmitForm(event: FormEvent) {
    event.preventDefault();
    apiRequest(createService(token, form));
  }

  useEffect(() => {
    if (apiError) {
      toast.error(apiError);
      setApiError(undefined);
    }
  }, [apiError, setApiError]);

  return (
    <>
      <AppBar position='static'>
        <Toolbar className={classes.toolbar}>
          <IconButton className={classes.iconButton} onClick={handleGoBack}>
            <GoBackIcon />
          </IconButton>
          <Typography variant='h6'>Criar serviço</Typography>
        </Toolbar>
      </AppBar>
      <form onSubmit={handleSubmitForm}>
        <CreateServiceForm
          form={form}
          setForm={setForm}
          airCrafts={airCrafts}
          bases={bases}
          hangars={hangars}
          airCraftsLoading={airCraftsLoading}
          basesLoading={basesLoading}
          hangarsLoading={hangarsLoading}
          apiLoading={apiLoading}
        />
      </form>
    </>
  );
};

export default CreateService;
