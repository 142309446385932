import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  ListItemText,
  ListItem,
  List,
  Divider,
  DialogTitle,
  DialogActions,
  ListItemIcon,
  TextField,
  Grid,
  DialogContent,
} from '@material-ui/core';
import FlightIcon from '@material-ui/icons/Flight';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import FilterModalList from '../FilterModalList';
import useAircraftModels from '../../hooks/useAircraftModels';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  aircraftFormWrapper: {
    width: '100%',
  },
  aircraftInputWrapper: {
    flex: 1,
    '& input': {
      textTransform: 'uppercase',
    },
  },
  aircraftIconWrapper: {
    display: 'inline-flex',
    justifyContent: 'flex-start',
    minWidth: '56px',
    flexShrink: 0,
    color: theme.palette.text.secondary,
  },
}));

export type FilterProps = {
  aircraft?: {
    _model?: {
      _id?: string;
    };
    prefix?: string;
  };
};

type FilterModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  setFilter: (state: FilterProps) => void;
};

const FilterModal = ({ open, setOpen, setFilter }: FilterModalProps) => {
  const classes = useStyles();
  const [aircraftsModelOpen, setAircraftsModelOpen] = useState(false);
  const [aircraftsModelValue, setAircraftsModelValue] = useState<
    string | undefined
  >();
  const [aircraftPrefix, setAircraftPrefix] = useState<string>();
  const { aircraftModelsData: data } = useAircraftModels();

  function handleCancelModal() {
    setOpen(false);
  }

  function handleSaveModal() {
    setFilter({
      aircraft: {
        _model: {
          _id: aircraftsModelValue,
        },
        prefix: aircraftPrefix,
      },
    });
    setOpen(false);
  }

  return (
    <>
      <Dialog fullWidth maxWidth='md' open={open} onClose={handleCancelModal}>
        <DialogTitle>Filtro de serviços</DialogTitle>
        <DialogContent>
          <List>
            <ListItem button>
              <ListItemIcon>
                <FlightTakeoffIcon />
              </ListItemIcon>
              <ListItemText
                primary='Filtrar por modelo de aeronave'
                onClick={() => setAircraftsModelOpen((oldValue) => !oldValue)}
              />
            </ListItem>
            <Divider />
            <ListItem>
              <Grid
                container
                spacing={1}
                alignItems='flex-end'
                className={classes.aircraftFormWrapper}
              >
                <Grid item className={classes.aircraftIconWrapper}>
                  <FlightIcon />
                </Grid>
                <Grid item className={classes.aircraftInputWrapper}>
                  <TextField
                    id='stardand-basic'
                    label='Aeronave'
                    value={aircraftPrefix}
                    onChange={(event) => {
                      setAircraftPrefix(event.target.value.toUpperCase());
                    }}
                    fullWidth
                    size='medium'
                  />
                </Grid>
              </Grid>
              <Divider />
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelModal} color='primary'>
            Cancelar
          </Button>
          <Button onClick={handleSaveModal} color='primary'>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
      {data && (
        <FilterModalList
          open={aircraftsModelOpen}
          keepMounted
          value={aircraftsModelValue || ''}
          onClose={(value) => {
            if (value) setAircraftsModelValue(value);
            setAircraftsModelOpen(false);
          }}
          data={data}
        />
      )}
    </>
  );
};

export default FilterModal;
